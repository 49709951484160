import { Divider, Grid, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { UserContext } from '../context/userContext';
import { useSearchParams } from 'react-router-dom';
import RoundLoader from '../components/roundLoader';
import styles from '../assets/styles/loginActivity.module.scss'
import { FixedSizeList as List } from 'react-window';
import tableStyles from '../assets/styles/existingCustomers.module.scss'
import agent from '../api/api';
import { checkForErrorCode, getErrorMessage } from '../utils/constants';
import GlobalButton from '../components/button';
import ChatbotLists from './ChatbotLists/chatbotLists';
import { ChatbotListContext } from '../context/chatbotListContext';
import ChatbotDetails from './ChatbotLists/chatbotDetails';

export default function LoginActivity({ sortedData, loginActivity, setLoginActivity, chatbotConversation }) {
    const [searchParams, setSearchParams] = useSearchParams()
    const [changeField, setChangeField] = useState("");
    const { setError, error, setFlagLoading, flagLoading, setSuccess } = React.useContext(UserContext);
    const { chatbotList } = React.useContext(ChatbotListContext);
    const user_id = searchParams.get('user_id');
    const chatbot_id = searchParams.get('chatbot_id')
    const [sortConfig, setSortConfig] = useState({ key: null, direction: '' });
    const [delegateAccess, setDelegateAccess] = useState(false)
    useEffect(() => {
        //codes
    }, [sortedData, chatbotConversation])

    const columns = [
        { field: 'user_access_token_create_timestamp', headerName: 'Login Time', width: "48%" },
        { field: 'logout_timestamp', headerName: 'Logout Time', width: "48%" },
    ];

    const handleSort = (key) => {
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setChangeField(key);
        const sorted = [...loginActivity].sort((a, b) => {
            const aValue = a[key] || "";
            const bValue = b[key] || "";

            if (aValue === "" && bValue === "") {
                return 0;
            }

            if (aValue === "") {
                return direction === 'ascending' ? -1 : 1;
            }

            if (bValue === "") {
                return direction === 'ascending' ? 1 : -1;
            }

            if (aValue < bValue) {
                return direction === 'ascending' ? -1 : 1;
            }

            if (aValue > bValue) {
                return direction === 'ascending' ? 1 : -1;
            }

            return 0;
        });

        setLoginActivity(sorted);
        setSortConfig({ key, direction });
    };

    const renderTableRow = ({ index, style }) => {
        const row = loginActivity[index];
        return (
            <TableRow className={tableStyles.tableBodyRow} style={style} key={row.id}>
                <TableCell sx={{ minWidth: "calc(24vw - 90px)", maxWidth: "100%" }} className={tableStyles.tableBodyCell}>
                    {/* <Tooltip title={row.user_assignment_id} classes={{ tooltip: classes.customTooltip }} placement='right-start'>{row.user_assignment_id}</Tooltip> */}
                    {row.user_access_token_create_timestamp}

                </TableCell>
                <TableCell sx={{ minWidth: "calc(24vw - 90px)", maxWidth: "100%" }} className={tableStyles.tableBodyCell}>
                    {/* <Tooltip title={row.userFirstName} classes={{ tooltip: classes.customTooltip }} placement='right-start'>{row.userFirstName?.length > 12 ? row.userFirstName.slice(0, 12) + "..." : row.userFirstName}</Tooltip> */}
                    {row.logout_timestamp || "-"}
                </TableCell>
            </TableRow>
        );
    };

    const renderTableColumn = () => {
        return (
            <TableRow className={tableStyles.tableHeadRow}>
                <Grid container direction={"row"} flexWrap={"nowrap"} gap={3}>
                    {columns.map((column) => (
                        <Grid item key={column.field} sx={{ minWidth: column.width, cursor: "pointer", padding: "5px 20px 5px 15px" }} onClick={() => handleSort(column.field)}>
                            <Grid container direction={"row"} justifyContent={"space-between"} flexWrap={"nowrap"} alignItems={"center"}>
                                <Grid item>
                                    {column.headerName}
                                </Grid>
                                <Grid item direction={"column"}>
                                    <Grid container direction={"column"}>
                                        <Typography variant='span' style={{ color: sortConfig.direction === "ascending" && column.field === changeField ? "rgba(0,0,0,0.85)" : "rgba(0,0,0,0.1)", lineHeight: "normal", fontSize: "12px" }}>▲</Typography>
                                        <Typography variant='span' style={{ color: sortConfig.direction === "descending" && column.field === changeField ? "rgba(0,0,0,0.85)" : "rgba(0,0,0,0.1)", lineHeight: "normal", fontSize: "12px" }}>▼</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    ))}
                </Grid>
            </TableRow>
        );
    };

    async function handleDelegateAccess() {
        let user_id = searchParams.get('user_id')
        if (user_id) {
            let payload = {
                delegate_private_user_id: Number(user_id)
            }
            const response = await agent.allowDelegateAccess.post(payload)
            if (response.status) {
                window.open(process.env.REACT_APP_CHRONOX_URL, '_blank')
                setDelegateAccess(true)
            } else {
                let errorCode = checkForErrorCode(response)
                if (errorCode == 9121) {
                    window.open(process.env.REACT_APP_CHRONOX_URL, '_blank')
                } else {
                    setError((prevState) => ({
                        ...prevState,
                        flagShowError: true,
                        message: getErrorMessage(errorCode, true)
                    }))
                }
            }
        }

    }
    return (
        <>
            {
                flagLoading && <RoundLoader />
            }
            <Grid container direction={"column"} alignItems={"end"}>
                <Grid item>
                    <GlobalButton
                        className='primaryButtonStyle'
                        buttonText="Get Delegate Access"
                        onClick={handleDelegateAccess}
                        inputProps={{ 'aria-label': 'controlled' }}
                    />
                </Grid>
            </Grid>
            <Divider style={{ width: "100%", opacity: "0.8", marginTop: "10px" }} />

            {!chatbot_id &&
                <Grid container flexWrap={"wrap"} justifyContent={"space-between"} mt={2} className={styles.fieldDetails}>
                    <Grid item className={styles.customerFields}>
                        <Grid container direction={"row"} alignItems={"center"}>
                            <Grid item xs={5.5}>
                                <span className={styles.fieldName}>User ID: </span>
                            </Grid>
                            <Grid item xs={6}>
                                <span className={styles.fieldValue}>{sortedData?.userId || "-"}</span>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item className={styles.customerFields}>
                        <Grid container direction={"row"} alignItems={"center"}>
                            <Grid item xs={5.5}>
                                <span className={styles.fieldName}>User Email: </span>
                            </Grid>
                            <Grid item xs={6}>
                                <span className={styles.fieldValue}>{sortedData?.userEmail || "-"}</span>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item className={styles.customerFields}>
                        <Grid container direction={"row"} alignItems={"center"}>
                            <Grid item xs={5.5}>
                                <span className={styles.fieldName}>User First Name: </span>
                            </Grid>
                            <Grid item xs={6}>
                                <span className={styles.fieldValue}>{sortedData?.userFirstName || "-"}</span>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item className={styles.customerFields}>
                        <Grid container direction={"row"} alignItems={"center"}>
                            <Grid item xs={5.5}>
                                <span className={styles.fieldName}>User Last Name: </span>
                            </Grid>
                            <Grid item xs={6}>
                                <span className={styles.fieldValue}>{sortedData?.userLastName || "-"}</span>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item className={styles.customerFields}>
                        <Grid container direction={"row"} alignItems={"center"}>
                            <Grid item xs={5.5}>
                                <span className={styles.fieldName}>User Mouseflow Record URL: </span>
                            </Grid>
                            <Grid item xs={6}>
                                <span className={`${styles.fieldValue} ${styles.mouseFlowStyle}`} onClick={() => window.open(sortedData?.mouseflow_record_url, '_blank')}>{sortedData?.mouseflow_record_url || "-"}</span>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item className={styles.customerFields}>
                        <Grid container direction={"row"} alignItems={"center"}>
                            <Grid item xs={5.5}>
                                <span className={styles.fieldName}>User Phone Number: </span>
                            </Grid>
                            <Grid item xs={6}>
                                <span className={styles.fieldValue}>{sortedData?.userPhoneNumber || "-"}</span>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item className={styles.customerFields}>
                        <Grid container direction={"row"} alignItems={"center"}>
                            <Grid item xs={5.5}>
                                <span className={styles.fieldName}>User Last Login: </span>
                            </Grid>
                            <Grid item xs={6}>
                                <span className={styles.fieldValue}>{sortedData?.userLastLogin || "-"}</span>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item className={styles.customerFields}>
                        <Grid container direction={"row"} alignItems={"center"}>
                            <Grid item xs={5.5}>
                                <span className={styles.fieldName}>User Registered: </span>
                            </Grid>
                            <Grid item xs={6}>
                                <span className={styles.fieldValue}>{sortedData?.userRegistered || "-"}</span>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item className={styles.customerFields}>
                        <Grid container direction={"row"} alignItems={"center"}>
                            <Grid item xs={5.5}>
                                <span className={styles.fieldName}>User Registered Timestamp: </span>
                            </Grid>
                            <Grid item xs={6}>
                                <span className={styles.fieldValue}>{sortedData?.registerTimestamp || "-"}</span>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item className={styles.customerFields}>
                        <Grid container direction={"row"} alignItems={"center"}>
                            <Grid item xs={5.5}>
                                <span className={styles.fieldName}>User Assignment ID: </span>
                            </Grid>
                            <Grid item xs={6}>
                                <span className={styles.fieldValue}>{sortedData?.user_assignment_id || ""}</span>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item className={styles.customerFields}>
                        <Grid container direction={"row"} alignItems={"center"}>
                            <Grid item xs={5.5}>
                                <span className={styles.fieldName}>User Status ID: </span>
                            </Grid>
                            <Grid item xs={6}>
                                <span className={styles.fieldValue}>{sortedData?.userStatusId || "-"}</span>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item className={styles.customerFields}>
                        <Grid container direction={"row"} alignItems={"center"}>
                            <Grid item xs={5.5}>
                                <span className={styles.fieldName}>User Status: </span>
                            </Grid>
                            <Grid item xs={6}>
                                <span className={styles.fieldValue}>{sortedData?.useStatusName || "-"}</span>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item className={styles.customerFields}>
                        <Grid container direction={"row"} alignItems={"center"}>
                            <Grid item xs={5.5}>
                                <span className={styles.fieldName}>User Role ID: </span>
                            </Grid>
                            <Grid item xs={6}>
                                <span className={styles.fieldValue}>{sortedData?.userRoleId || "-"}</span>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item className={styles.customerFields}>
                        <Grid container direction={"row"} alignItems={"center"}>
                            <Grid item xs={5.5}>
                                <span className={styles.fieldName}>User Role: </span>
                            </Grid>
                            <Grid item xs={6}>
                                <span className={styles.fieldValue}>{sortedData?.userRoleName || "-"}</span>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item className={styles.customerFields}>
                        <Grid container direction={"row"} alignItems={"center"}>
                            <Grid item xs={5.5}>
                                <span className={styles.fieldName}>User Persona ID: </span>
                            </Grid>
                            <Grid item xs={6}>
                                <span className={styles.fieldValue}>{sortedData?.userPersonaId || "-"}</span>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item className={styles.customerFields}>
                        <Grid container direction={"row"} alignItems={"center"}>
                            <Grid item xs={5.5}>
                                <span className={styles.fieldName}>High Potential User: </span>
                            </Grid>
                            <Grid item xs={6}>
                                <span className={styles.fieldValue}>{sortedData?.highPotentialUser || "-"}</span>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            }

            {!chatbot_id &&
                <Grid container direction={{xs: "column", md: "row"}} gap={4} flexWrap={"nowrap"}>
                    <Grid item xs={5.8}>
                        <Grid container mt={2} direction={"column"}>
                            <Grid item>
                                <span className={styles.headingName}>Login Activity Details</span>
                            </Grid>
                            <Grid item marginBlock={2}>
                                <TableContainer className={styles.tableContainer} sx={{ overflowX: "hidden" }}>
                                    <Table aria-label='simple table' className={styles.tableDesign}>
                                        <TableHead className={tableStyles.tableHead}>
                                            {renderTableColumn()}
                                        </TableHead>
                                        <TableBody className={tableStyles.tableBody}>
                                            {(loginActivity && loginActivity.length > 0) ?
                                                <List
                                                    height={750}  // Adjust this value based on the desired height of the table
                                                    itemCount={loginActivity.length}
                                                    itemSize={51}  // Adjust based on row height
                                                    width="100%"
                                                    className={tableStyles.listData}                                            >
                                                    {renderTableRow}
                                                </List>
                                                :
                                                <TableRow className={tableStyles.tableBodyRow}>
                                                    <TableCell sx={{ textAlign: "left", padding: "16px 48% 32px" }}>
                                                        <span style={{ position: "fixed" }}>No Data Available</span>
                                                    </TableCell>
                                                </TableRow>
                                            }
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                        </Grid>
                    </Grid>


                    <Grid item xs={5.8}>
                        <Grid container mt={2} direction={"column"}>
                            <Grid item>
                                <span className={styles.headingName}>Chatbot Conversation Details</span>
                            </Grid>
                            <Grid item marginBlock={2}>
                                {chatbotConversation &&
                                    <TableContainer className={`${styles.tableContainer} ${styles.chatbotConversation}`} sx={{ overflowX: "hidden" }}>
                                        <Table aria-label='simple table' className={styles.tableDesign}>
                                            <TableBody className={tableStyles.tableBody}>
                                                <TableRow className={tableStyles.tableBodyRow}>
                                                    <TableCell className={`${styles.fieldName} ${styles.firstCell}`}>Total Chatbots</TableCell>
                                                    <TableCell className={styles.fieldName}>{chatbotConversation.total_chatbot}</TableCell>
                                                </TableRow>
                                                <TableRow className={tableStyles.tableBodyRow}>
                                                    <TableCell className={`${styles.fieldName} ${styles.firstCell}`}>Total Conversations</TableCell>
                                                    <TableCell className={styles.fieldName}>{chatbotConversation.total_conversations}</TableCell>
                                                </TableRow>
                                                <TableRow className={tableStyles.tableBodyRow}>
                                                    <TableCell className={`${styles.fieldName} ${styles.firstCell}`}>Last 7 days Conversation</TableCell>
                                                    <TableCell className={styles.fieldName}>{chatbotConversation.last_seven_days_conversation}</TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            }

            {!chatbot_id && chatbotList && chatbotList.length > 0 &&
                <Grid container flexWrap={"nowrap"} direction={"column"} mt={1} gap={2}>
                    <Grid item xs={12}>
                        <span className={styles.headingName}>Chatbot Lists</span>
                    </Grid>
                    <Grid item xs={12}>
                        <ChatbotLists />
                    </Grid>
                </Grid>
            }

            {chatbot_id &&
                <Grid container mt={2}>
                    <ChatbotDetails />
                </Grid>
            }
        </>
    )
}
