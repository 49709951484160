import React, { useContext, useEffect, useState } from 'react'
import { ChatbotListContext } from '../../context/chatbotListContext'
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Button, Grid, TextField } from '@mui/material';
import styles from '../../assets/styles/chatbotLists.module.scss'
import { UserContext } from '../../context/userContext';
import FileUpload from './fileUpload';
import axios from 'axios';
import textTypeFileIcon from "../../assets/images/chatbotDetails/FileUploads/txtFileType.png";
import csvTypeFileIcon from "../../assets/images/chatbotDetails/FileUploads/csvFileType.png";
import mdTypeFileIcon from "../../assets/images/chatbotDetails/FileUploads/mdFileType.png";
import xmlTypeFileIcon from "../../assets/images/chatbotDetails/FileUploads/xmlFileType.png";
import pngTypeFileIcon from "../../assets/images/chatbotDetails/FileUploads/pngFileType.png";
import jpgTypeFileIcon from "../../assets/images/chatbotDetails/FileUploads/jpgFileType.png";
import jsonTypeFileIcon from "../../assets/images/chatbotDetails/FileUploads/jsonFileType.png";
import jpegTypeFileIcon from "../../assets/images/chatbotDetails/FileUploads/jpegFileType.png";
import genericFileType from "../../assets/images/chatbotDetails/FileUploads/genericFileType.png";
import agent from '../../api/api';
import GlobalButton from '../../components/button';
import DatePresets from './datePresets';
import { DateRangePicker } from 'react-dates';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import SearchIcon from '@mui/icons-material/Search';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import moment from 'moment';

const ChatbotDetails = () => {
    const navigate = useNavigate();
    const { getChatbotDetails, chatbotDetails, chatbotList, setStartDate, setEndDate, setFocusedInput, focusedInput, startDate, endDate, getChatbotListConversation } = useContext(ChatbotListContext);
    const { setFlagLoading, languageList } = useContext(UserContext);
    const [searchParams, setSearchParams] = useSearchParams();
    const chatbot_id = Number(searchParams.get('chatbot_id'));
    const user_id = Number(searchParams.get('user_id'))
    const [files, setFiles] = useState([]);
    const dateFormat = "DD/MM/YYYY";

    const handleChatbotDetail = () => {
        navigate(`${window.location.pathname}?user_id=${user_id}`)
    }

    const getFileTypeImgIcon = (fileType) => {
        switch (fileType) {
            case 'txt':
                return textTypeFileIcon
            case 'md':
                return mdTypeFileIcon
            case 'json':
                return jsonTypeFileIcon;
            case 'csv':
                return csvTypeFileIcon;
            case 'png':
                return pngTypeFileIcon;
            case 'jpg':
                return jpgTypeFileIcon;
            case 'xml':
                return xmlTypeFileIcon;
            case 'jpeg':
                return jpegTypeFileIcon;
            default:
                return genericFileType;
        }
    }

    const getFileData = async (id, page_field_id) => {
        const fileResponse = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/storage/file?file_id=${id}&flag_info=true`);
        let fileType = fileResponse.data.data.original_file_name.split('.').pop()
        let fileTypeIconSrc = getFileTypeImgIcon(fileType) ?? ""
        return { ...fileResponse.data.data, page_field_id: page_field_id, file_data: fileResponse.data.data, name: fileResponse.data.data.original_file_name, lastModified: fileResponse.data.data.uploaded_at, size: fileResponse.data.data.file_size, fileNewDesign: true, previewImageSrc: fileTypeIconSrc };
    };

    useEffect(() => {
        if(chatbot_id){
            setStartDate(moment().subtract(1, "month"));
            setEndDate(moment());
            getChatbotDetails(chatbot_id)
        }
    }, [chatbot_id])

    useEffect(() => {
        if (chatbotDetails != null) {
            (async () => {
                const knowledgeBaseArr = await Promise.all(chatbotDetails.chatbot_knowledge_base.map(async (knowledgeBase) => {
                    const fileResp = await getFileData(knowledgeBase.knowledge_base_id, '1514');
                    return fileResp;
                }));

                let fileArray = []
                if (chatbotDetails.chatbot_logo_id) {
                    let response = await agent.getFileDetail.get(Number(chatbotDetails?.chatbot_logo_id));
                    if (response.status) {
                        let fileTypeChatbotId = response.data.original_file_name.split('.').pop()
                        let fileTypeIconSrcChatbot = getFileTypeImgIcon(fileTypeChatbotId) ?? ""
                        let chatbotObj = {
                            ...response.data, page_field_id: 1515, file_data: response.data, name: response.data.original_file_name, lastModified: response.data.uploaded_at, previewImageSrc: fileTypeIconSrcChatbot, size: response.data.file_size, fileNewDesign: true
                        }
                        fileArray.push(chatbotObj)
                    }
                }
                if (chatbotDetails.chatbot_favicon_id) {
                    let response = await agent.getFileDetail.get(Number(chatbotDetails?.chatbot_favicon_id));
                    if (response.status) {
                        let fileTypeChatbotId = response.data.original_file_name.split('.').pop()
                        let fileTypeIconSrcChatbot = getFileTypeImgIcon(fileTypeChatbotId) ?? ""
                        let chatbotObj = {
                            ...response.data, page_field_id: 1516, file_data: response.data, name: response.data.original_file_name, lastModified: response.data.uploaded_at, previewImageSrc: fileTypeIconSrcChatbot, size: response.data.file_size, fileNewDesign: true
                        }
                        fileArray.push(chatbotObj)
                    }
                }
                let filesTemp = [...fileArray, ...knowledgeBaseArr]
                setFiles(filesTemp)
            })()
        } 
    }, [chatbotDetails])

    const formatDate = (dateString) => {
        return dateString ? dateString.format('YYYY-MM-DD') : null     
    };

    const handleSearchTokens = () => {
        setFlagLoading(true)
        getChatbotListConversation(user_id, formatDate(startDate), formatDate(endDate))
        setFlagLoading(false)
    }

    const handleResetSearchToken = () => {
        setFlagLoading(true)
        setStartDate(moment().subtract(1, "month"));
        setEndDate(moment());
        getChatbotListConversation(user_id, null, null)
        setFlagLoading(false)
    }

    return (
        chatbotDetails && <Grid item sx={{height: "calc(100vh - 170px)", maxHeight: "calc(100vh - 170px)", overflowY: "auto"}} xs={12}>
            <Grid container direction={"row"} flexWrap={"nowrap"} gap={2} paddingBottom={"15px"}>
                <Grid item xs={6} sx={{borderRadius: "5px"}} paddingInline={1}>
                    <Grid container direction={"column"} gap={2}>
                        <Grid item xs={12}>
                            <Grid container direction={"column"} gap={"6px"}>
                                <Grid item>
                                    <span className={styles.chatbotFormLabel}>Chatbot Name</span>
                                </Grid>
                                <Grid item>
                                    <TextField disabled className={styles.textFieldStyles} value={chatbotDetails?.chatbot_name} sx={{width: "100%"}} />
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12}>
                            <Grid container direction={"column"} gap={"6px"}>
                                <Grid item>
                                    <span className={styles.chatbotFormLabel}>Purpose of Chatbot</span>
                                </Grid>
                                <Grid item>
                                    <textarea disabled className={styles.textAreaStyles} rows={5} value={chatbotDetails?.chatbot_discription} />
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12}>
                            <Grid container direction={"column"} gap={"6px"}>
                                <Grid item>
                                    <span className={styles.chatbotFormLabel}>Initial Message</span>
                                </Grid>
                                <Grid item>
                                    <textarea disabled className={styles.textAreaStyles} rows={3} value={chatbotDetails?.chatbot_initial_message} />
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12}>
                            <Grid container direction={"column"} gap={"6px"}>
                                <Grid item>
                                    <span className={styles.chatbotFormLabel}>Chatbot Text Direction</span>
                                </Grid>
                                <Grid item>
                                    <TextField disabled className={styles.textFieldStyles} value={chatbotDetails?.chatbot_text_direction} sx={{width: "100%"}} />
                                </Grid>
                            </Grid>
                        </Grid>

                        {chatbotDetails && chatbotDetails.language_id &&
                            <Grid item xs={12}>
                                <Grid container direction={"column"} gap={"6px"}>
                                    <Grid item>
                                        <span className={styles.chatbotFormLabel}>Chatbot Primary Language</span>
                                    </Grid>
                                    <Grid item>
                                        <TextField disabled className={styles.textFieldStyles} value={languageList?.find((language) => (language.language_id == chatbotDetails?.language_id)).name} sx={{width: "100%"}} />
                                    </Grid>
                                </Grid>
                            </Grid>
                        }

                        {chatbotDetails && chatbotDetails?.chatbot_detail_message_instruction &&
                            <Grid item xs={12}>
                                <Grid container direction={"column"} gap={"6px"}>
                                    <Grid item>
                                        <span className={styles.chatbotFormLabel}>Chatbot Specific Instruction</span>
                                    </Grid>
                                    <Grid item>
                                        <textarea disabled className={styles.textAreaStyles} rows={5} value={chatbotDetails?.chatbot_detail_message_instruction} />
                                    </Grid>
                                </Grid>
                            </Grid>
                        }

                    </Grid>
                </Grid>

                <Grid item xs={6} sx={{borderRadius: "5px", position: "relative"}} paddingInline={1}>
                    <Grid container direction={"column"} gap={2}>
                        <Grid item xs={12} sx={{display: "contents !important"}}>
                            <Grid container direction={"column"}>
                                <Grid item>
                                    <span className={styles.chatbotFormLabel}>Knowledge Base (txt, pdf or json file only)</span>
                                </Grid>
                                <Grid item sx={{display: "contents !important"}}>
                                    <FileUpload filesFromParent={files} page_field_id={1514} />
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} sx={{display: "contents !important"}}>
                            <Grid container direction={"column"}>
                                <Grid item>
                                    <span className={styles.chatbotFormLabel}>Chatbot Profile Picture</span>
                                </Grid>
                                <Grid item sx={{display: "contents !important"}}>
                                    <FileUpload filesFromParent={files} page_field_id={1515}/>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} sx={{display: "contents !important"}}>
                            <Grid container direction={"column"} gap={"6px"}>
                                <Grid item>
                                    <span className={styles.chatbotFormLabel}>Widget Icon</span>
                                </Grid>
                                <Grid item sx={{display: "contents !important"}}>
                                    <FileUpload filesFromParent={files} page_field_id={1516}/>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} sx={{boxShadow: "0px 0px 3px rgba(0,0,0,0.25)", borderRadius: "10px", padding: "20px", backgroundColor: "#F9F9F9"}}>
                            <Grid container direction={"column"} gap={3}>
                                <Grid item>
                                    <Grid container direction={"column"} gap={"10px"}>
                                        <Grid item>
                                            <span className={styles.chatbotFormLabel}>Tokens</span>
                                        </Grid>
                                        <Grid item className={styles.dateRangePickerClass} xs={6}>
                                            <Grid container direction={"row"} alignItems={"center"} flexWrap={"nowrap"} gap={2}>
                                                <Grid item>
                                                    <DateRangePicker
                                                        startDate={startDate}
                                                        startDateId='s_id'
                                                        endDate={endDate}
                                                        endDateId='e_id'
                                                        onDatesChange={({ startDate, endDate }) => {
                                                            setStartDate(startDate);
                                                            setEndDate(endDate);
                                                        }}
                                                        focusedInput={focusedInput}
                                                        displayFormat={dateFormat}
                                                        isOutsideRange={() => false}
                                                        onFocusChange={(focusedInput) => setFocusedInput(focusedInput)}
                                                        renderCalendarInfo={() => (
                                                            <DatePresets
                                                                startDate={startDate}
                                                                endDate={endDate}
                                                                dateFormat={dateFormat}
                                                                handlePresets={(start, end) => {
                                                                    setStartDate(start);
                                                                    setEndDate(end);
                                                                }}
                                                            />
                                                        )}                                         
                                                    />
                                                </Grid>
                                                <Grid item>
                                                    <Button 
                                                        sx={{borderRadius: "50%", padding: "8px 8px", minWidth: "0"}}
                                                        onClick={() => handleSearchTokens()}
                                                    >
                                                        <SearchIcon sx={{display: "flex", cursor: "pointer", color: "#000000"}}/>
                                                    </Button>
                                                    <Button 
                                                        sx={{borderRadius: "50%", padding: "8px 8px", minWidth: "0"}}
                                                        onClick={() => handleResetSearchToken()}
                                                    >
                                                        <RotateLeftIcon sx={{display: "flex", cursor: "pointer", color: "#000000"}}/>
                                                    </Button>
                                                </Grid>
                                            </Grid>                                         
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item>
                                    <Grid container direction={"row"} gap={3} flexWrap={"nowrap"}>
                                        <Grid item sx={{width: "100%"}}>
                                            <Grid container direction={"column"} gap={"10px"}>
                                                <Grid item>
                                                    <span className={styles.chatbotFormLabel}>Chatbot Input Tokens</span>
                                                </Grid>
                                                <Grid item>
                                                    <TextField disabled className={styles.textFieldStyles} value={chatbotList.find((list) => Number(list.chatbot_id) === chatbot_id)?.chatbot_input_tokens} sx={{width: "100%"}} />
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <Grid item sx={{width: "100%"}}>
                                            <Grid container direction={"column"} gap={"10px"}>
                                                <Grid item>
                                                    <span className={styles.chatbotFormLabel}>Chatbot Output Tokens</span>
                                                </Grid>
                                                <Grid item>
                                                    <TextField disabled className={styles.textFieldStyles} value={chatbotList.find((list) => Number(list.chatbot_id) === chatbot_id)?.chatbot_output_tokens} sx={{width: "100%"}} />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>                                    
                                </Grid>
                            </Grid>                        
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item sx={{position: "fixed", right: "50px", bottom: "10px"}}>
                    <GlobalButton
                        className='primaryButtonStyle'
                        buttonText="Move to Customer Details"
                        onClick={() => handleChatbotDetail()}
                        inputProps={{ 'aria-label': 'controlled' }}
                    />
                </Grid>
            </Grid>
        </Grid>
    )
}

export default ChatbotDetails
