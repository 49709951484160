import { Breadcrumbs, Button, Divider, Grid, InputLabel, Link, TextField, TextareaAutosize } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import styles from '../../assets/styles/chatbotsystem.module.scss'
import globalStyles from '../../assets/styles/global.module.scss'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { UserContext } from '../../context/userContext'
import Required from '../../components/required'
import agent from '../../api/api'

const CreateViewChatbotSystem = () => {
    const navigate = useNavigate();
    const { setFlagLoading, setError, error, flagLoading, setSuccess } = useContext(UserContext);
    const [ searchParams, setSearchParams ] = useSearchParams();
    const [ existingSystemVariableDetail, setExistingSystemVariableDetail ] = useState({
        chatbot_system_variable_name: "",
        chatbot_system_variable_content: ""
    })
    const [ chatbotSystemVariableDetail, setChatbotSystemVariableDetail ] = useState()
    const [ chatbotSystemVariablePayload, setChatbotSystemVariablePayload ] = useState({
        chatbot_system_variable_name: "",
        chatbot_system_variable_content: ""
    });
    let variableId = searchParams.get('chatbot_system_variable_id');

    const getChatbotSystemVariableDetails = async (variableId) => {
        setFlagLoading(true);
        const response = await agent.getChatbotSystemVariableDetail.get(variableId);
        if (response.status) {
          setChatbotSystemVariableDetail(response.data[0])
          setExistingSystemVariableDetail((prevState) => ({ ...prevState, chatbot_system_variable_name: response.data[0].chatbot_system_variable_name, chatbot_system_variable_content: response.data[0].chatbot_system_variable_content }))
          setChatbotSystemVariablePayload((prevState) => ({ ...prevState, chatbot_system_variable_name: response.data[0].chatbot_system_variable_name, chatbot_system_variable_content: response.data[0].chatbot_system_variable_content }))
          setFlagLoading(false);
        } else {
          setChatbotSystemVariableDetail()
          setFlagLoading(false)
        }
    }

    useEffect(() => {
        setFlagLoading(true)
        let variable_id = searchParams.get('chatbot_system_variable_id');
        if(variable_id){
            getChatbotSystemVariableDetails(variable_id)
        }
        setTimeout(() => {
            setFlagLoading(false)
        }, 500);
    }, [])

    const handleChangePayload = (value, key) => {
        if(key === "chatbot_system_variable_name"){
            setChatbotSystemVariablePayload((prevState) => ({ ...prevState, chatbot_system_variable_name: value }))
        } else {
            setChatbotSystemVariablePayload((prevState) => ({ ...prevState, chatbot_system_variable_content: value }))
        }
    }

    const cancelChatbotSystemVariable = () => {
        if(variableId) {
            setChatbotSystemVariablePayload((prevState) => 
                ({ ...prevState, 
                    chatbot_system_variable_name: chatbotSystemVariableDetail.chatbot_system_variable_name,
                    chatbot_system_variable_content: chatbotSystemVariableDetail.chatbot_system_variable_content 
                }))
        } else {
            setChatbotSystemVariablePayload({
                chatbot_system_variable_name: "",
                chatbot_system_variable_content: ""
            })
        }

    }

    const cancelChatbotSystemVariableDisable = () => {
        if (variableId) {
            if (JSON.stringify(chatbotSystemVariablePayload) == JSON.stringify(existingSystemVariableDetail)) {
                return true
            } else {
                return false
            }
        } else {
            if(chatbotSystemVariablePayload.chatbot_system_variable_name.trim().length === 0 && chatbotSystemVariablePayload.chatbot_system_variable_content.trim().length === 0){
                return true
            }
            return false
        }
    }

    const handleUpdateChatbotSystemVariable = async () => {
        setFlagLoading(true);
            let payload = { ...chatbotSystemVariablePayload, chatbot_system_variable_id: variableId }
            const response = await agent.updateChatbotSystemVariable.put(payload);
            if (response.status && response.data) {
                setExistingSystemVariableDetail(response.data[0])
                // navigate(`/dashboard/chatbotSystem/createChatbotSystem?chatbot_system_variable_id=${response.data[0].chatbot_system_variable_id}`)
                navigate(`/dashboard/chatbotSystem`)
                setSuccess((prevState) => ({ ...prevState, flagShowSuccess: true, message: "Chatbot System Variable Updated Successfully!" }))
            } else {
                setError({
                    ...error,
                    flagShowError: true,
                    message: response?.error?.message
                })
            }
        setFlagLoading(false);
    }

    const handleSaveChatbotSystemVariable = async () => {
        setFlagLoading(true);
            const payload = { ...chatbotSystemVariablePayload }
            const response = await agent.createChatbotSystemVariable.post(payload);
            if (response.status && response.data) {
                setExistingSystemVariableDetail(response.data[0])
                // navigate(`/dashboard/chatbotSystem/createChatbotSystem?chatbot_system_variable_id=${response.data[0].chatbot_system_variable_id}`)
                navigate(`/dashboard/chatbotSystem`)
                setSuccess((prevState) => ({ ...prevState, flagShowSuccess: true, message: "Chatbot System Variable Created Successfully!" }))
            } else {
                setError({
                    ...error,
                    flagShowError: true,
                    message: response?.error?.message
                })
            }
        setFlagLoading(false);
    }

    const getHandleSaveChatbotSystemDisable = () => {
        if(variableId){
            if ((JSON.stringify(chatbotSystemVariablePayload) == JSON.stringify(existingSystemVariableDetail)) || chatbotSystemVariablePayload.chatbot_system_variable_name.trim().length === 0 || chatbotSystemVariablePayload.chatbot_system_variable_content.trim().length === 0) {
                return true
            }
        } else {
            if (chatbotSystemVariablePayload.chatbot_system_variable_name.trim().length > 0 && chatbotSystemVariablePayload.chatbot_system_variable_content.trim().length > 0) {
                return false
            } else {
                return true
            }
        }     
    }

  return (
    <>
    {!flagLoading &&
        <Grid container>
            <Grid item xs={12}>
                <Grid container direction={"column"} gap={1} paddingBlock={"30px 15px"}>
                    <Grid item className={globalStyles.globalHeaderFontStyle}>{variableId ? chatbotSystemVariableDetail?.chatbot_system_variable_name : "Create Chatbot System Variable"}</Grid>
                    <Grid item>
                        <Breadcrumbs
                            aria-label="breadcrumb"
                            className={styles.cbHeaderBreadCrumb}
                            sx={{
                                '& .MuiBreadcrumbs-separator': {
                                    marginRight: '3px',
                                    marginLeft: '3px'
                                }
                            }}
                        >
                            <Link
                                underline="hover"
                                onClick={() => { navigate("/dashboard/chatbotSystem") }}
                                className={`${styles.cbHeaderBreadCrumbsHeadText} ${globalStyles.clickable}`}
                            >
                                Chatbot System KB
                            </Link>

                            <Link
                                underline="hover"
                                className={`${styles.cbHeaderBreadCrumbsChatbotLabel} ${globalStyles.clickable}`}

                            >
                                {/* {chatBotDetails?.chatbot_name ? chatBotDetails?.chatbot_name : "Chatbot"} */}
                                {variableId ? chatbotSystemVariableDetail?.chatbot_system_variable_name : "Create Chatbot System Variable"}
                            </Link>
                        </Breadcrumbs>
                    </Grid>
                </Grid>
                <Divider className={globalStyles.fullwidth}/>
            </Grid>

            <Grid item xs={12} className={styles.chatbotCreateMainItem}>
                <Grid container gap={3}>
                    <Grid item xs={12}>
                        <Grid container direction={"column"}>
                            <Grid item mb={1}>
                                <InputLabel className={globalStyles.formLabelFontStyle}>Chatbot System Variable Name <Required /></InputLabel>
                            </Grid>
                            <Grid item>
                                <TextField 
                                    placeholder='Chatbot System Variable Name' 
                                    // defaultValue={chatbotSystemVariableDetail[0].chatbot_system_variable_name}
                                    value={chatbotSystemVariablePayload.chatbot_system_variable_name}
                                    onChange={(event) => handleChangePayload(event.target.value, "chatbot_system_variable_name")} 
                                    className={`${globalStyles.fullwidth} ${styles.variableNameTextfield}`}
                                />
                            </Grid>
                        </Grid>  
                    </Grid>   

                    <Grid item xs={12}>
                        <Grid container direction={"column"}>
                            <Grid item mb={1}>
                                <InputLabel className={globalStyles.formLabelFontStyle}>Chatbot System Variable Content <Required /></InputLabel>
                            </Grid>
                            <Grid item>
                                <TextareaAutosize 
                                    placeholder='Chatbot System Variable Content' 
                                    value={chatbotSystemVariablePayload.chatbot_system_variable_content}
                                    onChange={(event) => handleChangePayload(event.target.value, "chatbot_system_variable_content")} 
                                    minRows={12.5}
                                    maxRows={12.5}
                                    className={styles.variableContentTextArea}
                                />
                            </Grid>
                        </Grid>  
                    </Grid>

                    <Grid item xs={12} className={styles.buttonGrid}>
                        <Grid container sx={{ justifyContent: "start" }} className={styles.cbSaveCancelContainer} gap={2} justifyContent={"end"}>
                            <Grid item>
                                <Button
                                    variant="outlined"
                                    size="medium"
                                    fullWidth
                                    className={styles.cbCancelButton}
                                    onClick={cancelChatbotSystemVariable}
                                    disabled={cancelChatbotSystemVariableDisable()}
                                    sx={{ opacity: cancelChatbotSystemVariableDisable() ? "0.5" : "" }}
                                >
                                    Cancel
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    variant="contained"
                                    size="medium"
                                    fullWidth
                                    className={styles.cbSaveButton}
                                    disabled={getHandleSaveChatbotSystemDisable()}
                                    onClick={variableId ? handleUpdateChatbotSystemVariable : handleSaveChatbotSystemVariable}
                                    sx={{ opacity: getHandleSaveChatbotSystemDisable() ? "0.5" : "" }}
                                >
                                    {variableId ? "Update" : "Save"}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>            
                </Grid>           
            </Grid>
        </Grid>
    }
    </>
  )
}

export default CreateViewChatbotSystem
