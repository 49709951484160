import React from 'react'
import agent from '../api/api';

const ChatbotSystemContext = React.createContext();

const ChatbotSystemDetailsProvider = ({ children }) => {

  const [chatbotSystemList, setChatbotSystemList] = React.useState([])

  const getChatbotSystemList = async () => {
    const response = await agent.GetChatbotSystemList.get();
    if (response.status) {
      setChatbotSystemList(response.data)
    } else {
      setChatbotSystemList([])
    }
  }

  const payload = {
      chatbotSystemList,
      setChatbotSystemList,
      getChatbotSystemList,
  };

  return (
      <ChatbotSystemContext.Provider value={payload}>{children}</ChatbotSystemContext.Provider>
  );
};

export {ChatbotSystemContext, ChatbotSystemDetailsProvider};
