import React from 'react'
import { useState } from 'react';
import agent from '../api/api';
import moment from 'moment';

const ChatbotListContext = React.createContext();

const ChatbotListsDetailsProvider = ({ children }) => {
    
  const [chatbotList, setChatbotList] = React.useState([]);
  const [chatbotConversation, setChatbotConversation] = useState(null);
  const [chatbotDetails, setChatbotDetails] = useState(null);
  const [startDate, setStartDate] = useState(moment().subtract(1, "month"))
  const [endDate, setEndDate] = useState(moment());
  const [focusedInput, setFocusedInput] = useState(null);

  const getChatbotListConversation = async (user_id, start_date, end_date) => {
      const response2 = await agent.getChatbotConversationDetails.get(user_id, start_date, end_date)
      if (response2.status) {
          setChatbotConversation(response2.chat_status)
          setChatbotList(response2.data)
      } else {
          setChatbotConversation(null)
          setChatbotList([])
      }
  }

  const getChatbotDetails = async (chatbot_id) => {
    const response = await agent.getUserChatbotDetails.get(chatbot_id);
    if(response.status && response.data) {
        setChatbotDetails(response.data);
    } else {
        setChatbotDetails(null)
    }
  }

  const payload = {
      chatbotList,
      setChatbotList,
      chatbotConversation,
      setChatbotConversation,
      getChatbotListConversation,
      getChatbotDetails,
      chatbotDetails,
      setChatbotDetails,
      startDate,
      setStartDate,
      endDate,
      setEndDate,
      focusedInput,
      setFocusedInput
  };

  return (
      <ChatbotListContext.Provider value={payload}>{children}</ChatbotListContext.Provider>
  );
};

export {ChatbotListContext, ChatbotListsDetailsProvider};
