import React, { useEffect, useRef, useState } from "react"
import styled from "@emotion/styled"
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CloseIcon from '@mui/icons-material/Close';

const Wrapper = styled.div`
  width: 200px;
  height: auto;
  display: flex;

  box-shadow: ${props =>
        props.raised ? "6px 5px 22px -8px rgba(0,0,0,0.76)" : "none"};
  padding: 10px;
  border-radius: 6px;
  justify-content: space-between;
  align-items: center;
  position: relative;
  &:hover {
    cursor: pointer;
  }
`

const SelectedItemsContainer = styled.div`
  display: flex;
  flex-grow: 1;
  flex-wrap: wrap;
`

const SelectIcon = styled.span`
  display: "inline-block";
  width: 20px;
`

const DropDown = styled.ul`
  position: absolute;
  top: ${({ wrapperClientHeight }) => wrapperClientHeight - 10}px;
  left: 0px;
  height: "inherit";

  opacity: ${({ opened }) => (opened ? 1 : 0)};
  visibility: ${({ opened }) => (opened ? "visible" : "hidden")};
  width: inherit;
  box-shadow: ${props =>
        props.raised ? "6px 5px 22px -8px rgba(0,0,0,0.76)" : "none"};
  border-radius: 6px;
  padding: 6px;
  transition: all 0.2s linear 0.1s;
  & > li {
    list-style: none;
    padding: 8px;

    &:hover {
      background-color: papayawhip;
    }
  }
`

const Chip = styled.span`
  background: #e8f7f6;
  padding: 6px;
  border-radius: 4px;
  margin: 4px;
  & > span {
    margin-right: 8px;
  }
`

export const MultiSelect = ({
    raised = true,
    items,
    placeholder = "Select an Item",
    onChange
}) => {
    const [selectedItems, setSelectedItems] = useState([])
    const [opened, setIsOpened] = useState(false)
    const wrapperRef = useRef(null)
    const [wrapperClientHeight, setWrapperClientHeight] = useState(40)

    const onClickWrapper = () => {
        setIsOpened(!opened)
    }

    const onClickDeleteItem = field_option_data_id => {

        var tempData = {}
        tempData['field_option_data_id'] = field_option_data_id
        tempData['field_option_flag'] = false
        onChange(tempData)
        setSelectedItems(selectedItems.filter(item => item.field_option_data_id !== field_option_data_id))
    }

    const onDropDownClicked = async newItem => {

        newItem['field_option_flag'] = true
        onChange(newItem)

        await setSelectedItems([...selectedItems, newItem])
    }

    useEffect(() => {
        if (wrapperRef && wrapperRef.current) {
            if (selectedItems.length > 0) {
                const newwrapperClientHeight =
                    wrapperRef && wrapperRef.current
                        ? wrapperRef.current.clientHeight
                        : 25

                setWrapperClientHeight(newwrapperClientHeight)
            } else {
                setWrapperClientHeight(40)
            }
        }
    }, [selectedItems])

    useEffect(() => {

        if (selectedItems.length === items.length) {
            setIsOpened(false)
        }

    }, [selectedItems, items])

    const filteredItems = items.filter(
        item => selectedItems.findIndex(sel => sel.field_option_data_id === item.field_option_data_id) === -1
    )

    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
                setIsOpened(false);
            }
        };

        document.addEventListener("click", handleOutsideClick);

        return () => {
            document.removeEventListener("click", handleOutsideClick);
        };
    }, []);

    return (
        <Wrapper raised={raised} onClick={onClickWrapper} ref={wrapperRef}>
            <SelectedItemsContainer>
                {selectedItems.length === 0 && <span>{placeholder} </span>}
                {selectedItems.map(({ field_option_data_id, field_option_value }) => (
                    <Chip key={field_option_data_id} onClick={e => e.stopPropagation()}>
                        {" "}
                        <span>{field_option_value}</span>
                        <span
                            onClick={e => {
                                e.stopPropagation()
                                onClickDeleteItem(field_option_data_id)
                            }}
                        >
                            <CloseIcon icon={CloseIcon} fontSize="10px" color="gray" />
                        </span>
                    </Chip>
                ))}
            </SelectedItemsContainer>
            <SelectIcon>
                {opened ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </SelectIcon>
            {filteredItems.length > 0 && (
                <DropDown
                    opened={opened}
                    raised={raised}
                    wrapperClientHeight={wrapperClientHeight}
                >
                    {filteredItems.map(({ field_option_data_id, field_option_value }) => (
                        <li
                            key={field_option_data_id}
                            onClick={e => {
                                // console.log("eee in onclick", e);
                                e.stopPropagation()
                                onDropDownClicked({ field_option_data_id, field_option_value })
                            }}
                        >
                            {" "}
                            {field_option_value}
                        </li>
                    ))}
                </DropDown>
            )}
        </Wrapper>
    )
}
