// UserDetailsContext.jsx
import React, { useContext, useEffect, useState } from "react";

const UserDetailsContext = React.createContext();

const UserDetailsProvider = ({ children }) => {

    const [usersDetails, setUsersDetails] = useState([])

    const payload = {
        usersDetails,
        setUsersDetails
    };

    return (
        <UserDetailsContext.Provider value={payload}>{children}</UserDetailsContext.Provider>
    );
};

export { UserDetailsContext, UserDetailsProvider };
