import React, { useContext } from 'react'
import { ChatbotListContext } from '../../context/chatbotListContext'
import { Grid, Typography } from '@mui/material'
import chatbotProfileAvatar from '../../assets/images/chatbotDetails/chatbotProfileAvtar.png'
import styles from '../../assets/styles/chatbotLists.module.scss'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { UserContext } from '../../context/userContext'

const ChatbotLists = () => {
    const navigate = useNavigate();
    const { chatbotList } = useContext(ChatbotListContext);
    const { setFlagLoading } = useContext(UserContext);
    const [searchParams, setSearchParams] = useSearchParams()
    const user_id = searchParams.get('user_id')

    const handleViewChatbot = (chatbot_id) => {
        setFlagLoading(true)
        navigate(`${window.location.pathname}?user_id=${user_id}&chatbot_id=${chatbot_id}`)
        setFlagLoading(false);
    } 

    return (
        <Grid container direction={"row"} flexWrap={"wrap"}>
            {chatbotList.map((chatbots) => {
                    return <Grid item className={styles.chatbotCardComponent} onClick={() => handleViewChatbot(chatbots.chatbot_id)}>
                        <Grid container direction={"column"}>
                            <Grid item xs={12}>
                                <Grid container justifyContent={"space-between"} alignItems={"start"}>
                                    <Grid item className={styles.chatbotProfileAvtarItem}
                                        sx={{
                                            background: chatbots?.chatbot_logo_id ? "transparent" : "#FAF0FF"
                                        }}
                                    >
                                        <img src={chatbots?.chatbot_logo_url ? chatbots.chatbot_logo_url : chatbotProfileAvatar} alt="chatbot Icon" className={chatbots?.chatbot_logo_id ? styles.chatbot_icon : styles.default_icon} />
                                    </Grid>

                                    {chatbots.chatbot_flag_public &&
                                        <Grid item sx={{ width: "auto" }} marginLeft={1}>
                                            <Grid item sx={{ background: "rgba(10, 106, 221, 0.1)", color: "#0A6ADD", padding: "7px 10px", fontSize: "13px", fontWeight: "500", boxShadow: "0px 0px 2px 0px rgba(10, 106, 221, 0.5)", borderRadius: "5px" }}>
                                                Sample Chatbot
                                            </Grid>
                                        </Grid>
                                    }
                                </Grid>
                            </Grid>
                            
                            <Grid item xs={12} className={styles.chatbotNameItem}>
                                <Typography component={'span'} className={`${styles.chatbotHeadText} ${styles.cardHeaderFontSize}`}>{chatbots.chatbot_name}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                }
            )}
        </Grid>
    )
}

export default ChatbotLists
