import { Backdrop, Box, CircularProgress, Typography } from '@mui/material';
import React, { useContext, useState, useEffect } from 'react';

const RoundLoader = () => {

    return (
        <React.Fragment>
            <Backdrop
                sx={{ color: '#fff', zIndex: 1500, opacity: "0.8 !important" }}
                open={true}
            >
                <Box sx={{
                    display: 'flex',
                    justifyContent: "center",
                    alignItems: 'center',
                    flexDirection: 'column',
                    textAlign: 'center'
                }}>
                    <CircularProgress color="inherit"
                        sx={{
                            width: '50px !important',
                            height: '50px !important'
                        }}
                    />
                </Box>
            </Backdrop>
        </React.Fragment>
    )
};

export default RoundLoader;