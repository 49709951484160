export function checkError(response) {
    if (response.isAxiosError && response.response && response.response.data && response.response.data.error && response.response.data.error.message) {
        return response.response.data.error.message;
    } else {
        return response.error?.message || "Something Went Wrong!";
    }
}

export const errorCodes = {
    "ERR_GENERAL": {
        "code": 9000,
        "message": "Something went wrong with the server.",
        "userMessage": "Something went wrong! Please try again later."
    },
    "ERR_INVALID_BODY": {
        "code": 9001,
        "message": "Invalid body!",
        "userMessage": "Something went wrong! Please try again later."
    },
    "ERR_INVALID_QUERY_PARAMETER": {
        "code": 9002,
        "message": "ERROR! Invalid query parameter.",
        "userMessage": "Something went wrong! Please try again later."
    },
    "ERR_BAD_REQUEST": {
        "code": 9003,
        "message": "ERROR! bad request found.",
        "userMessage": "Something went wrong! Please try again later."
    },
    "ERR_INVALID_RECAPTCHA": {
        "code": 9004,
        "message": "ERROR! Invalid re-Captcha found.",
        "userMessage": "ERROR! Invalid re-Captcha found."
    },
    "USER_CREATED_SUCCESSFULLY": {
        "code": 9005,
        "message": "User created successfully!",
        "userMessage": "User created successfully!"
    },
    "ERR_USER_ALREADY_EXIST": {
        "code": 9006,
        "message": "This user already exist. Please try using another email address.",
        "userMessage": "This user already exist. Please try using another email address."
    },
    "ERR_USER_NOT_VERIFIED": {
        "code": 9007,
        "message": "ERROR! User not verified.",
        "userMessage": "ERROR! User not verified."
    },
    "ERR_USER_ALREADY_ACTIVE": {
        "code": 9008,
        "message": "ERROR! This user is already active.",
        "userMessage": "ERROR! This user is already active."
    },
    "ERR_USER_NOT_FOUND": {
        "code": 9009,
        "message": "ERROR! User not found.",
        "userMessage": "ERROR! User not found."
    },
    "OTP_SENT_SUCCESSFULLY": {
        "code": 9010,
        "message": "We have successfully sent a verfication code to your email address.",
        "userMessage": "We have successfully sent a verfication code to your email address."
    },
    "USER_VERIFIED_SUCCESSFULLY": {
        "code": 9011,
        "message": "Congratulations! You've been verified successfully.",
        "userMessage": "Congratulations! You've been verified successfully."
    },
    "ERR_INVALID_OTP": {
        "code": 9012,
        "message": "ERROR! Invalid code. Please input valid code.",
        "userMessage": "ERROR! Invalid code. Please input valid code."
    },
    "ERR_INVALID_EMAIL": {
        "code": 9013,
        "message": "This user does not exist in our system. Please try using another email address.",
        "userMessage": "This user does not exist in our system. Please try using another email address."
    },
    "USER_LOGGED_IN_SUCCESSFULLY": {
        "code": 9014,
        "message": "You are Logged in Successfully!",
        "userMessage": "You are Logged in Successfully!"
    },
    "USER_LOGGED_OUT_SUCCESSFULLY": {
        "code": 9015,
        "message": "You are Logged out Successfully!",
        "userMessage": "You are Logged out Successfully!"
    },
    "ERR_PUBLIC_USER_ALREADY_EXIST": {
        "code": 9016,
        "message": "ERROR! Anonymous user already exist.",
        "userMessage": "ERROR! Anonymous user already exist."
    },
    "ERR_REQ_VALUE_NOT_FOUND": {
        "code": 9017,
        "message": "ERROR! Please provide '{{field}}' for {{prompt_name}}.",
        "userMessage": "Something went wrong! Please try again later!"
    },
    "ERR_COUNT_LIMIT_EXCEEDS": {
        "code": 9018,
        "message": "ERROR! Count limit exceeds.",
        "userMessage": "ERROR! Credit limit exceeded!"
    },
    "SOCKET_CONNECT_SUCCESSFULLY": {
        "code": 9019,
        "message": "Socket connection established successfully.",
        "userMessage": ""
    },
    "ERR_ACCESS_NOT_GRANTED": {
        "code": 9020,
        "message": "ERROR! Access not granted.",
        "userMessage": "ERROR! Access not granted."
    },
    "NO_CHANGE_FOUND": {
        "code": 9021,
        "message": "No change found!",
        "userMessage": "No change found!"
    },
    "DATA_CHANGED_SUCCESSFULLY": {
        "code": 9022,
        "message": "Data updated successfully.",
        "userMessage": "Data updated successfully."
    },
    "ERR_NO_MORE_CREDIT_LEFT": {
        "code": 9023,
        "message": "Sorry!! No more credit left.",
        "userMessage": "Sorry!! No more credit left."
    },
    "CREDITS_WITHDRAWN_SUCCESSFULLY": {
        "code": 9024,
        "message": "Credits withdrawn successfully.",
        "userMessage": "Credits withdrawn successfully."
    },
    "ERR_INVALID_PROMPT_PROPERTY": {
        "code": 9025,
        "message": "ERROR! Invalid prompt property!",
        "userMessage": "Something went wrong, Please try again later!"
    },
    "ERR_TEMPLATE_FIELD_ID_NOT_FOUND": {
        "code": 9026,
        "message": "ERROR! Template Form Field Specified is Invalid!",
        "userMessage": "Something went wrong, Please try again later!"
    },
    "DATA_CREATED_SUCCESSFULLY": {
        "code": 9027,
        "message": "Data Created Successfully!",
        "userMessage": "Data Created Successfully!"
    },
    "ERR_WHILE_EXECUTING_MSSQL_QUERY": {
        "code": 9028,
        "message": "Server error occurred while executing Database query.",
        "userMessage": "Something went wrong, Please try again later!"
    },
    "ERR_WHILE_UPLODING_FILE": {
        "code": 9029,
        "message": "Error while uploding file.",
        "userMessage": "Something went wrong, Please try again later!"
    },
    "ERR_DOWNLOAD_FILE_FROM_GOOGLE": {
        "code": 9030,
        "message": "Error occurred while downloading file.",
        "userMessage": "Error occurred while downloading file."
    },
    "ERR_DELETE_FILE_FROM_GOOGLE": {
        "code": 9031,
        "message": "Error occurred while deleting file.",
        "userMessage": "Something went wrong. Please try again later!"
    },
    "ERR_DATA_NOT_FOUND": {
        "code": 9032,
        "message": "Data not found.",
        "userMessage": "Data not found."
    },
    "ERR_DOMAIN_NOT_FOUND": {
        "code": 9033,
        "message": "Domain not found.",
        "userMessage": "Something went wrong. Please try again later!"
    },
    "ERR_INVALID_DOMAIN_NAME": {
        "code": 9034,
        "message": "Invalid domain name.",
        "userMessage": "Something went wrong. Please try again later!"
    },
    "ERR_ORGANIZATION_NOT_FOUND": {
        "code": 9035,
        "message": "ERROR! Organization not found.",
        "userMessage": "ERROR! Organization not found."
    },
    "ERR_USER_ALREADY_EXIST_IN_ORGANIZATION": {
        "code": 9036,
        "message": "ERROR! User is already associate with another organization.",
        "userMessage": "ERROR! User is already associate with another organization."
    },
    "ERR_EMAIL_ADDRESS_NOT_FOUND": {
        "code": 9037,
        "message": "Please provide your email address.",
        "userMessage": "Please provide your email address."
    },
    "ERR_USERNAME_NOT_FOUND": {
        "code": 9038,
        "message": "Please provide your name.",
        "userMessage": "Please provide your name."
    },
    "ERR_MESSAGE_NOT_FOUND": {
        "code": 9039,
        "message": "Please provide appropriate message.",
        "userMessage": "Please provide appropriate message."
    },
    "MESSAGE_RECIEVED_SUCCESSFULLY": {
        "code": 9040,
        "message": "Your message has been received successfully, and we will be in touch with you shortly.",
        "userMessage": "Your message has been received successfully, and we will be in touch with you shortly."
    },
    "ERR_AI_MODULE": {
        "code": 9041,
        "message": "ERROR! creating persona. Please try again after sometime.",
        "userMessage": "Something went wrong! Please try again later."
    },
    "ERR_NO_ORGANIZATION_FOUND": {
        "code": 9042,
        "message": "ERROR! No organization found for this user.",
        "userMessage": "ERROR! No organization found for this user."
    },
    "TEMPLATE_DELETED_SUCCESSFULLY": {
        "code": 9043,
        "message": "Template deleted successfully.",
        "userMessage": "Template deleted successfully."
    },
    "ERR_EMAIL_ADDRESS_BLACKLIST": {
        "code": 9044,
        "message": "ERROR! Please provide a valid email address.",
        "userMessage": "ERROR! Please provide a valid email address."
    },
    "ERR_HEAVY_LOAD_TO_SERVER": {
        "code": 9045,
        "message": "Please try again later. Our system is currently experiencing a high server load.",
        "userMessage": "Please try again later. Our system is currently experiencing a high server load."
    },
    "ERR_INVALID_TOKEN": {
        "code": 9046,
        "message": "Token expired or invalid. Please try again later.",
        "userMessage": "Something went wrong! Please try again later."
    },
    "ERR_INVALID_ORGANIZATION": {
        "code": 9047,
        "message": "Invalid organization found.",
        "userMessage": "Invalid organization found."
    },
    "DOCUMENT_DELETED_SUCCESSFULLY": {
        "code": 9048,
        "message": "Document deleted successfully.",
        "userMessage": "Document deleted successfully."
    },
    "ERR_PERSONA_DELETED_SUCCESSFULLY": {
        "code": 9049,
        "message": "Persona deleted successfully.",
        "userMessage": "Persona deleted successfully."
    },
    "DATA_SAVED_SUCCESSFULLY": {
        "code": 9050,
        "message": "Data Saved Successfully!",
        "userMessage": "Data Saved Successfully!"
    },
    "ERR_AI_EXECUTION": {
        "code": 9051,
        "message": "Sorry, we encountered an unexpected issue processing your input. Please try again later.",
        "userMessage": "Sorry, we encountered an unexpected issue processing your input. Please try again later."
    },
    "ERR_CANNOT_DELETE": {
        "code": 9052,
        "message": "Cannot delete all documents.",
        "userMessage": "Sorry, we encountered an unexpected issue processing your input. Please try again later."
    },
    "ERR_INVALID_FILE_TYPE": {
        "code": 9053,
        "message": "Invalid file type.",
        "userMessage": "Invalid file type."
    },
    "ERR_INVALID_AUTHENTICATION_FOUND": {
        "code": 9054,
        "message": "Invalid authentication found.",
        "userMessage": "Invalid authentication found."
    },
    "ERR_ALREADY_EXISTS": {
        "code": 9055,
        "message": "Already exists",
        "userMessage": "Already exists"
    },
    "CAMPAIGN_DELETED_SUCCESSFULLY": {
        "code": 9056,
        "message": "Campaign deleted successfully.",
        "userMessage": "Campaign deleted successfully."
    },
    "ERR_SOMTHING_WANT_WRONG": {
        "code": 9057,
        "message": "Something went wrong.",
        "userMessage": "Something went wrong."
    },
    "CAMPAIGN_DOCUMNET_BEING_CREATED": {
        "code": 9058,
        "message": "Campaign documents are currently being generated. Please wait for their completion.",
        "userMessage": "Campaign documents are currently being generated. Please wait for their completion."
    },
    "DEFAULT_PERSONA_REMOVED": {
        "code": 9059,
        "message": "Persona removed from default successfully.",
        "userMessage": "Persona removed from default successfully."
    },
    "ERR_DIRECTORY_FOUND": {
        "code": 9060,
        "message": "ERROR! It looks like the directory you're trying to access doesn't exist or may have been removed.",
        "userMessage": "ERROR! It looks like the directory you're trying to access doesn't exist or may have been removed."
    },
    "ERR_CHAT_THREAD_NOT_FOUND": {
        "code": 9061,
        "message": "ERROR! It looks like the chat thread you're trying to access doesn't exist or may have been removed.",
        "userMessage": "ERROR! It looks like the chat thread you're trying to access doesn't exist or may have been removed."
    },
    "ERR_USER_CHAT_LIMIT_EXCEED": {
        "code": 9062,
        "message": "Chat Limit Reached: Please start a new conversation thread with Chat, as this one has reached the limit and won't retain past context.",
        "userMessage": "Chat Limit Reached: Please start a new conversation thread with Chat, as this one has reached the limit and won't retain past context."
    },
    "ERR_STREAM_NOT_SUPPORTED": {
        "code": 9063,
        "message": "Sorry! Stream is not supported.",
        "userMessage": "Something went wrong, Please try again later!"
    },
    "DATA_DELETED_SUCCESSFULLY": {
        "code": 9064,
        "message": "{{deleted_data_name}} deleted successfully.",
        "userMessage": "Something went wrong, Please try again later!"
    },
    "ERR_CANNOT_DELETE_PROMPT": {
        "code": 9065,
        "message": "Before deleting prompt, first delete all prompt variant & prompt property.",
        "userMessage": "Something went wrong, Please try again later!"
    },
    "ERR_PROMPT_PROPERTY_ALREADY_EXISTS": {
        "code": 9066,
        "message": "ERROR! prompt property that you are trying to add that already exists.",
        "userMessage": "Something went wrong, Please try again later!"
    },
    "ERR_CANNOT_DELTE_PROMPT_PROPERTY": {
        "code": 9067,
        "message": "ERROR! Before deleting prompt property remove property from each variant",
        "userMessage": "Something went wrong, Please try again later!"
    },
    "ERR_INVALID_MODEL": {
        "code": 9068,
        "message": "ERROR! Invalid Model.",
        "userMessage": "Something went wrong, Please try again later!"
    },
    "ERR_PROMPT_LOG_FIELD_ALREADY_EXISTS": {
        "code": 9069,
        "message": "ERROR! prompt log field that you are trying to add that already exists.",
        "userMessage": "Something went wrong, Please try again later!"
    },
    "ERR_INVALID_PROMPT_LOG_FIELD": {
        "code": 9070,
        "message": "Invalid prompt log field found.",
        "userMessage": "Something went wrong, Please try again later!"
    },
    "ERR_USER_HEAVY_LOAD_TO_SERVER": {
        "code": 9071,
        "message": "Sorry, you've reached your daily usage limit.",
        "userMessage": "Sorry, you've reached your daily usage limit."
    },
    "ERR_FILE_SIZE_EXCEED": {
        "code": 9072,
        "message": "ERROR! Your file size is too large to upload.",
        "userMessage": "ERROR! Your file size is too large to upload."
    },
    "ERR_UPLOADING_FILE": {
        "code": 9073,
        "message": "Error occurred while uploading file.",
        "userMessage": "Error occurred while uploading file."
    },
    "ERR_INVALID_JSON_FOUND": {
        "code": 9074,
        "message": "ERROR! Invalid JSON Found.",
        "userMessage": "Something went wrong! Please try again later."
    },
    "ERR_ALREADY_INTEGRATED": {
        "code": 9075,
        "message": "ERROR! We found that your chatbot is already integrated to one of your account.",
        "userMessage": "ERROR! We found that your chatbot is already integrated to one of your account."
    },
    "ERR_PHONE_NUMBER_NOT_FOUND": {
        code: 9076,
        message: "ERROR! Please add atleast one phone number to your WhatsApp Business Account to continue integration.",
        userMessage: "ERROR! Please add atleast one phone number to your WhatsApp Business Account to continue integration."
    },
    "ERR_PHONE_NUMBER_ALREADY_INTEGRATED": {
        code: 9077,
        message: "ERROR! This phone number is already integrated to one of the chatbot.",
        userMessage: "ERROR! This phone number is already integrated to one of the chatbot."
    },
    "ERR_TRAVEL_AGENT_ALREADY_EXIST": {
        code: 9078,
        message: "ERROR! This travel agent already exist in our system.",
        userMessage: "Something went wrong! Please try again later."
    },
    "ERR_USER_PHONE_NUMBER_ALREADY_EXIST": {
        code: 9079,
        message: "Error! The phone number linked to another Agent already exists in our system.",
        userMessage: "Error! The phone number linked to another Agent already exists in our system."
    },

    ERR_INVALID_USER_CHATBOT_ACCESS: {
        code: 9080,
        message: "Hello {{username}}, your access to this service is currently restricted. Please reach out to your service provider to continue using this service.",
        userMessage: "Hello, your access to this service is currently restricted. Please reach out to your service provider to continue using this service."
    },
    ERR_CHATBOT_SERVICE_PAUSED: {
        code: 9081,
        message: "Hello {{username}}, this service has been deactivated and is currently unavailable. For further assistance, please reach out to the service provider.",
        userMessage: "Hello, this service has been deactivated and is currently unavailable. For further assistance, please reach out to the service provider."
    },
    ERR_CHATBOT_SERVICE_EXPIRED: {
        code: 9082,
        message: "Hello {{username}}, this service is currently unavailable as it has expired. Please reach out to the service provider for further assistance."
    },
    ERR_TEMPLATE_ALREADY_EXIST: {
        code: 9083,
        message: "ERROR! Template Name Already Exist",
        userMessage: "ERROR! Template Name Already Exist"
    },
    ERR_INVALID_FUNCTION_METHOD: {
        code: 9084,
        message: "ERROR! Invalid Method found.",
        userMessage: "ERROR! Invalid Method found.",
    },
    ERR_FUNCTION_URL_NOT_FOUND: {
        code: 9085,
        message: "ERROR! Invalid URL found.",
        userMessage: "ERROR! Invalid URL found.",
    },
    ERR_DATA_CONNECTOR_FOUND_INVALID: {
        code: 9086,
        message: "ERROR! Invalid data connector found.",
        userMessage: "ERROR! Invalid data connector found.",
    },
    ERR_DC_FUNCTION_NAME_NF: {
        code: 9087,
        message: "ERROR! Data connector function name not found.",
        userMessage: "ERROR! Data connector function name not found."
    },
    ERR_DC_FUNCTION_DESC_NF: {
        code: 9088,
        message: "ERROR! Data connector function description not found.",
        userMessage: "ERROR! Data connector function description not found."
    },
    ERR_DATA_CONNECTOR_FUNCTION_FOUND_INVALID: {
        code: 9089,
        message: "ERROR! Invalid data connector function found.",
        userMessage: "ERROR! Invalid data connector function found."
    },
    ERR_HEADER_KEY_NOT_FOUND: {
        code: 9090,
        message: "Header key is missing.",
        userMessage: "Header key is missing."
    },
    ERR_HEADER_KEY_VALUE_NOT_FOUND: {
        code: 9091,
        message: "Value for the given header key is missing.",
        userMessage: "Value for the given header key is missing."
    },
    ERR_HEADER_FOUND_INVALID: {
        code: 9092,
        message: "ERROR! Invalid header.",
        userMessage: "ERROR! Invalid header."
    },
    ERR_HEADER_KEY_ALREADY_EXIST: {
        code: 9093,
        message: "The provided Header Key already exists. Please provide a unique one.",
        userMessage: "The provided Header Key already exists. Please provide a unique one."
    },
    ERR_URL_METHOD_ALREADY_EXIST: {
        code: 9094,
        message: "The provided URL '{{url}}' with method '{{method_name}}' already exists.",
        userMessage: "The provided URL already exists."
    },
    ERR_DATA_CONNECTOR_FUNCTION_NAME_ALREADY_EXIST: {
        code: 9095,
        message: "The provided function '{{function_name}}' already exists.",
        userMessage: "The provided function already exists."
    },
    ERR_FUNCTION_RESPONSE_FOUND_EMPTY: {
        code: 9096,
        message: "The provided response of the function founds to be empty.",
        userMessage: "The provided response of the function founds to be empty."
    },
    ERR_FUNCTION_PARAMS_INVALID: {
        code: 9097,
        message: "ERROR! Invalid parameter.",
        userMessage: "Something went wrong! Please try again later!"
    },
    ERR_FUNCTION_PARAMETER_FOUND_EMPTY: {
        code: 9098,
        message: "The provided parameter of the function founds to be empty.",
        userMessage: "The provided parameter of the function founds to be empty."
    },
    ERR_PARAMS_ALREADY_EXIST: {
        code: 9099,
        message: "The provided parameter in function {{function_name}} already exist.",
        userMessage: "The provided parameter in function already exist."
    },
    ERR_PARAMS_NAME_NOT_FOUND: {
        code: 9100,
        message: "Parameter name not found in function {{function_name}}.",
        userMessage: "Parameter name not found in function"
    },
    ERR_PARAMS_DESC_NOT_FOUND: {
        code: 9101,
        message: "Parameter description not found in function {{function_name}}.",
        userMessage: "Parameter description not found in function"
    },
    ERR_PARAMS_TYPE_INVALID: {
        code: 9102,
        message: "The provided parameter type found to be invalid.",
        userMessage: "The provided parameter type found to be invalid."
    },
    ERR_FUNCTION_RESPONSE_INVALID: {
        code: 9103,
        message: "ERROR! Invalid response.",
        userMessage: "ERROR! Invalid response."
    },
    ERR_RES_NAME_NOT_FOUND: {
        code: 9104,
        message: "Response name not found in function {{function_name}}.",
        userMessage: "Response name not found in function"
    },
    ERR_RES_DESC_NOT_FOUND: {
        code: 9105,
        message: "Response description not found in function {{function_name}}.",
        userMessage: "Response description not found in function!"
    },
    ERR_RES_ALREADY_EXIST: {
        code: 9106,
        message: "The provided response in function {{function_name}} already exist.",
        userMessage: "The provided response already exist."
    },
    ERR_NOTES_CATEGORY_ALREADY_EXIST: {
        code: 9107,
        message: "ERROR! Notes Category Already Exist",
        userMessage: "ERROR! Notes Category Already Exist"
    },
    ERR_CHABOT_SUBSCRIPTION_ALREADY_EXIST: {
        code: 9108,
        message: "ERROR! The User Has Already Subscribed To The Chatbot",
        userMessage: "ERROR! The User Has Already Subscribed To The Chatbot"
    },
    CHATBOT_SUBSCRIPTION_STARTED_SUCCESSFULLY: {
        code: 9109,
        message: `Chatbot {{plan_name}} Plan Subscription Started Successfully!`,
        userMessage: "Chatbot Plan Subscription Started Successfully!"
    },
    ERR_NO_INTEGRATION_FOUND: {
        code: 9110,
        message: "ERROR! No Integration found with this chatbot.",
        userMessage: "ERROR! No Integration found with this chatbot."
    },
    WA_TEMPLATE_CREATED: {
        code: 9111,
        message: "WhatsApp template created successfully.",
        userMessage: "WhatsApp template created successfully."
    },
    ERR_INVALID_PARAMS: {
        code: 9112,
        message: "ERROR! Invalid params.",
        userMessage: "Somthing went wrong. Please try again later!"
    },
    ERR_PAGE_ALREADY_INTEGRATED: {
        code: 9113,
        message: "The Facebook page you are attempting to integrate is already connected to our system.",
        userMessage: "The Facebook page you are attempting to integrate is already connected to our system."
    },
    ERR_CHATBOT_SYSTEM_VARIABLE_ALREADY_EXISTS: {
        code: 9114,
        message: "ERROR! Chatbot system variable already exist.",
        userMessage: "ERROR! Chatbot system variable already exist."
    },
    USER_INVITED_ORG_SUCCESSFULLY: {
        code: 9115,
        message: "User invited to organization successfully.",
        userMessage: "User invited to organization successfully."
    },
    ERR_TOKEN_INVALID_EXPIRE: {
        code: 9116,
        message: "ERROR! Invitation token is invalid or expired.",
        userMessage: "ERROR! Invitation token is invalid or expired."
    },
    USER_REMOVED_SUCCESSFULLY: {
        code: 9117,
        message: "User removed successfully.",
        userMessage: "User removed successfully."
    },
    ERR_USER_ALREADY_IN_ORG: {
        code: 9118,
        message: "ERROR! User already exists in the organization.",
        userMessage: "ERROR! User already exists in the organization."
    },
    DELEGATE_ACCESS_GRANTED: {
        code: 9119,
        message: "Delegate access granted successfully.",
        userMessage: "Delegate access granted successfully."
    },
    DELEGATE_ACCESS_REVOKED: {
        code: 9120,
        message: "Delegate access revoked successfully.",
        userMessage: "Delegate access revoked successfully."
    },
    ERR_DELEGATE_ACCESS_ALREADY_GRANTED: {
        code: 9121,
        message: "ERROR! Delegate access is already granted.",
        userMessage: "ERROR! Delegate access is already granted."
    },
    ERR_DELEGATE_ACCESS_ALREADY_REVOKED: {
        code: 9122,
        message: "ERROR! Delegate access is already revoked.",
        userMessage: "ERROR! Delegate access is already revoked."
    },
    ERR_DELEGATE_ACCESS_INVALID: {
        code: 9123,
        message: "ERROR! Invalid user, delegate access can not be granted.",
        userMessage: "ERROR! Invalid user, delegate access can not be granted."
    },
    CALENDLY_INTEGRATED_SUCCESSSFULLY: {
        code: 9124,
        message: "Calendly integrated successfully.",
        userMessage: "Calendly integrated successfully."
    },
    ERR_PERSISTENT_MENU_ALREADY_EXISTS: {
        code: 9125,
        message: "ERROR! Persistent menu already exist.",
        userMessage: "ERROR! Persistent menu already exist."
    },
    ERR_DC_FUNCTION_RESPONSE: {
        code: 9126,
        message: "Invalid response. Reason: {{reason}}.",
        userMessage: "Something went wrong! Please try again later!"
    },
    ERR_DC_FUNCTION_PARAMS: {
        code: 9127,
        message: "Invalid parameters. Reason: {{reason}}.",
        userMessage: "Something went wrong! Please try again later!"
    },
    ACUITY_INTEGRATED_SUCCESSSFULLY: {
        code: 9128,
        message: "Acuity integrated successfully.",
        userMessage: "Acuity integrated successfully."
    },
    "INVALID_JSON_FROM_BE": {
        "code": 1110,
        "message": "ERROR! We found that your chatbot is already integrated to one of your account.",
        "userMessage": "Oops! Something went wrong! Please try again later."
    },
    Nothing_MATCHED: {
        code: 1111,
        message: "No code matched!!",
        userMessage: "Something went wrong! Please try again later."
    },
}
export function getErrorMessage(code) {
    for (const key in errorCodes) {
        if (errorCodes[key].code === code) {
            const errorCode = errorCodes[key];
            return errorCode.message
        }
    }
    const nothingMatched = errorCodes.Nothing_MATCHED;
    return nothingMatched.message
}
export function checkForErrorCode(response) {
    let errorCode = response.isAxiosError ? (response.response && response.response.data ? (response?.response?.data?.error?.code || response?.response?.data?.error?.statusCode || response?.data?.error?.code || response?.data?.error?.statusCode || response) : response) : (response?.error?.code || response?.error.statusCode);
    return errorCode;
}

export const moment = require('moment-timezone');
export function convertISTtoUTC(dateTimeStringIST) {
    if (dateTimeStringIST !== "") {
        // Parse the input date-time as IST
        const momentIST = moment.tz(dateTimeStringIST, "YYYY-MM-DD HH:mm:ss", "Asia/Kolkata");

        // Convert the moment instance to UTC
        const momentUTC = momentIST.utc();

        // Format the UTC date-time in the desired format
        return momentUTC.format("YYYY-MM-DD HH:mm:ss");
    } else {
        return ""
    }
}
export function convertUTCtoIST(dateTimeStringUTC) {
    if (dateTimeStringUTC !== "") {
        // Parse the input date-time as UTC
        const momentUTC = moment.utc(dateTimeStringUTC, "YYYY-MM-DD HH:mm:ss");

        // Convert the moment instance to IST
        const momentIST = momentUTC.tz("Asia/Kolkata");

        // Format the IST date-time in the desired format
        return momentIST.format("YYYY-MM-DD HH:mm:ss");
    } else {
        return "";
    }
}
export const GetCurrentActiveHeaderName = (pathname) => {
    switch (pathname) {
        case "/dashboard/existingCustomers":
            return "Existing Customers"
        case "/dashboard/chatbotSystem":
            return "Chatbot System KB"
        case "/dashboard/chatbotSystem/createChatbotSystem":
            return ""
        case "/dashboard/existing-customers":
            return "Customers"
        case "/dashboard/user":
            return "User"
        case "/dashboard/loaderDetails":
            return "Loader Details"
        default:
            return "Existing Customers"
    }
}

export function formatDate(dateString) {
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const date = new Date(dateString);

    const day = date.getDate().toString().padStart(2, '0');
    const month = months[date.getMonth()];
    const year = date.getFullYear().toString().substring(2);

    let hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const ampm = hours >= 12 ? 'PM' : 'AM';

    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    const formattedTime = `${hours}:${minutes} ${ampm}`;

    return `${day}-${month}-${year} ${formattedTime}`;
}