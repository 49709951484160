import { IconButton, InputAdornment, TextField } from '@mui/material'
import React from 'react'
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import globalStyles from "../assets/styles/global.module.scss"

export default function GlobalSearch(props) {
    return (
        <>
            <TextField
                placeholder={props.placeholder}
                onChange={props.onChange}
                value={props.value}
                className={globalStyles.globalSearchField}
                InputProps={{
                    startAdornment: (
                        <InputAdornment className={globalStyles.globalSearchInputAdornment}>
                            <SearchIcon className={globalStyles.clickable} />
                        </InputAdornment>
                    ),
                    endAdornment: (
                        props.value?.length > 0 && (
                            <IconButton
                                onClick={props.onClose}
                                edge="end"
                            >
                                <ClearIcon />
                            </IconButton>
                        )
                    )
                }}
            />
        </>
    )
}
