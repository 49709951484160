import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import SideNavigationPanel from "../components/sideNavigationPanel";
import home from "../assets/images/sideNavigationPanel/home.svg";
import homeWhite from "../assets/images/sideNavigationPanel/homeWhite.svg";
import { Grid } from '@mui/material';
import { Outlet } from 'react-router-dom';
import globalStyles from "../assets/styles/global.module.scss";
import { UserContext } from '../context/userContext';
import Header from '../components/header';
import userWhite from '../assets/images/sideNavigationPanel/UserWhiteIcon.png'
import userBlack from '../assets/images/sideNavigationPanel/UserBlackIcon.png'

export default function DevDashboard() {
    const pathname = window.location.pathname;
    const drawerWidth = 240;
    const menuItems = [
        {
            menuName: "Existing Customers",
            menuKey: "existingcustomers",
            imgSrc: home,
            whiteImgSrc: homeWhite,
            navigationLink: "/dashboard/existingCustomers"
        },
        // {
        //     menuName: "User",
        //     menuKey: "user",
        //     imgSrc: userBlack,
        //     whiteImgSrc: userWhite,
        //     navigationLink: "/dashboard/user"
        // },
        {
            menuName: "Loader Details",
            menuKey: "loaderDetails",
            imgSrc: userBlack,
            whiteImgSrc: userWhite,
            navigationLink: "/dashboard/loaderDetails"
        },
        {
            menuName: "Chatbot System KB",
            menuKey: pathname.includes('createChatbotSystem') ? "chatbotsystemvariable" : "chatbotsystem",
            imgSrc: userBlack,
            whiteImgSrc: userWhite,
            navigationLink: "/dashboard/chatbotSystem"
        },
    ]
    const { userDetail, setFlagLoading } = React.useContext(UserContext);
    const [mobileOpen, setMobileOpen] = React.useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    return (
        <>
            <Grid container>
                <Grid item sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}>
                    <SideNavigationPanel
                        open={mobileOpen}
                        onClose={handleDrawerToggle}
                        menuItems={menuItems}
                    />
                </Grid>
                <Grid item sx={{ width: { sm: `calc(100% - ${drawerWidth}px)` } }}>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        className={globalStyles.dashboardNavigationToggleButtonStyle}
                        sx={{ mr: 2, display: { sm: 'none' } }}

                    >
                        <MenuIcon />
                    </IconButton>

                    <Grid container className={globalStyles.containerflexWrapStyle} sx={{ justifyContent: "space-between" }} direction={"column"}>
                        <Grid item>
                            <Header flagShowLogo={false} />
                        </Grid>
                        <Grid item sx={{ height: "calc(100vh - 64px)" }}>
                            <Grid className={globalStyles.dashboardOutletWrapper} container>
                                <Outlet />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}
