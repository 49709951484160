import { Button, Grid, Typography } from '@mui/material'
import React, { useContext } from 'react'
import styles from "../../assets/styles/previewLoader.module.scss";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import Loader from "../../assets/images/loaderDetails/Customize-Loader.gif";
import loaderBgDefault from "../../assets/images/loaderDetails/Loader-bg.png";

const PreviewLoader = (props) => {
    const createDoc = () => {
    }
    const cancelCall = () => {
    }
    const getLoaderFunction = (functionName) => {
        switch (functionName) {
            case 'createDoc': {
                return createDoc();
            }
        }
    }
    return (
        <>
            <Grid container justifyContent={"center"}>
                <Grid item
                    className={styles.loaderGrid}
                >
                    <Grid container>

                        <Grid item xs={12} className={styles.prLoaderItem1}>
                            <Grid container className={styles.prLoaderContainer1}>
                                <Grid item>
                                    <img src={Loader} className={styles.customizeLoaderGif} alt="" />
                                </Grid>
                                <Grid item>
                                    <Typography component='span' className={styles.prLoaderBufferText}>
                                        Generating your content...
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Grid container>
                                        <Grid item>
                                            <Button
                                                onClick={cancelCall}
                                                variant='contained'
                                                fullWidth
                                                className={styles.prRecordingBtn}
                                                startIcon={<FiberManualRecordIcon style={{ color: "#E33629", fontSize: "15px", fontWeight: '500' }} />}
                                            >
                                                Stop Generating
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} className={styles.prLoaderItem2} sx={{ backgroundImage: props.bgImg ? props.bgImg : `url(${loaderBgDefault})` }}>
                            <Grid container direction={"column"} alignItems={"start"} justifyContent={'center'}>
                                <Grid item xs={12}>
                                    <Typography component={'span'} sx={{ display: "flex", alignItems: "baseline" }} className={styles.prLoaderHeadText}>
                                        <img src={props.icon} alt="icon" style={{ height: "16px", width: "16px", paddingRight: "6px", paddingTop: "0px" }} />
                                        <div
                                            dangerouslySetInnerHTML={{ __html: props.image_heading }}
                                        />
                                    </Typography>
                                </Grid>

                                <Grid item xs={12} className={styles.prDescTextItem}>
                                    <Typography component={'span'} className={styles.prDescText}>
                                        <div
                                            dangerouslySetInnerHTML={{ __html: props.image_description }}
                                        />
                                    </Typography>
                                </Grid>

                                <Grid item xs={12} className={styles.prBloggingBtnItem}>
                                    <Grid container>
                                        <Grid item>
                                            <Button
                                                variant='contained'
                                                size='medium'
                                                fullWidth
                                                className={styles.prBloggingBtn}
                                                onClick={() => getLoaderFunction(props.ftnName)}
                                            >
                                                {props.btnName}
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>

                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default PreviewLoader