import { Button, Grid, IconButton } from '@mui/material'
import React, { useContext, useEffect } from 'react'
import globalStyles from '../../assets/styles/global.module.scss'
import styles from '../../assets/styles/chatbotsystem.module.scss'
import AddIcon from '@mui/icons-material/Add'
import createPersonaIcon from "../../assets/images/chatbotSystem/createPersona.png";
import { UserContext } from '../../context/userContext'
import { ChatbotSystemContext } from '../../context/chatbotSystemContext'
import { useNavigate } from 'react-router-dom'
import { formatDate } from '../../utils/constants'


const ChatbotSystemDetails = () => {
    const navigate = useNavigate();
    const { setFlagLoading } = useContext(UserContext);
    const { chatbotSystemList, getChatbotSystemList } = useContext(ChatbotSystemContext);
    useEffect(() => {
        (async () => {
            setFlagLoading(true);
            getChatbotSystemList();
            setTimeout(() => {
                setFlagLoading(false);
            }, 500);
        })()
    }, [])

    const handleCreateChatbotSystem = () => {
        navigate("/dashboard/chatbotSystem/createChatbotSystem");
    }

    console.log(chatbotSystemList, "chatbotList")
  return (
    <>
        <Grid container>
            <Grid item sx={{borderTop: "1px solid #d4cdcd"}} xs={12} mt={2}>
                <Grid container direction={"row"} justifyContent={"end"} gap={3} alignItems={"center"} marginBlock={2}>
                    <Grid item className={`${globalStyles.globalNewAddBtnItem} ${styles.addChatbotBtn}`}>
                        <Button
                            variant='outlined'
                            size='large'
                            fullWidth
                            startIcon={<img src={createPersonaIcon} alt="" style={{ height: "18px", width: 'auto' }} />}
                            className={globalStyles.addDocumentBtn}
                            onClick={() => { handleCreateChatbotSystem() }}
                        >
                            Create Chatbot System
                        </Button>
                    </Grid>
                    <IconButton
                        className={`${globalStyles.cmpGlobalMobileAddIcon} ${styles.addChatbotIcon}`}
                        onClick={() => { handleCreateChatbotSystem() }}
                    >
                        <AddIcon style={{ height: "28px", width: 'auto' }} />
                    </IconButton>
                </Grid>
            </Grid>
            <Grid item>
                <Grid container>
                    {
                        chatbotSystemList.map((chatbotSystem) => (
                            <>
                            <Grid item className={styles.chatbotCard} onClick={() => navigate(`/dashboard/chatbotSystem/createChatbotSystem?chatbot_system_variable_id=${chatbotSystem.chatbot_system_variable_id}`)}>
                                <Grid container direction={"column"} gap={2} justifyContent={"center"} height={"100%"}>
                                    <Grid item className={globalStyles.cardHeaderFontStyle}>{chatbotSystem.chatbot_system_variable_name}</Grid>
                                    <Grid item className={globalStyles.cardDescriptionFontStyle}>{formatDate(chatbotSystem.created_at_timestamp)}</Grid>
                                </Grid>
                            </Grid>
                            </>
                        ))
                    }
                </Grid>
            </Grid>
        </Grid>
    </>
  )
}

export default ChatbotSystemDetails
