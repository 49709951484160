import axios, { AxiosError } from 'axios';

axios.defaults.withCredentials = true;
axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;
// axios.defaults.baseURL = "http://192.168.1.2:3000";

const additionalKey = 'route_source'; // The key you want to add
const additionalValue = 'Web App'; // The value for that key

const addKeyToPayload = (body) => {
    // Automatically adds the key-value pair to every payload
    return { ...body, [additionalKey]: additionalValue };
};

const addKeyToParams = (url) => {
    // Automatically adds the key-value pair to every URL as query parameters
    const urlObject = new URL(url, window.location.origin);
    urlObject.searchParams.set(additionalKey, additionalValue);
    return urlObject.pathname + urlObject.search;
};
const responseBody = (response) => {
    if (response?.Title === undefined)
        return response.data
}

const Error = (err) => {
    // @ts-ignore
    if (err?.response !== null && err?.response !== undefined) {
        if ((err?.response.status === 401 || err?.response.status === 403) && window.location.pathname !== "/") {
            window.location.pathname = "/";
        }
        else if (err?.response?.status == 404 && !window.location.pathname.includes("page-not-found")) {
            window.location.pathname = "/page-not-found";
        }
    }
    return err;
}

const requests = {
    get: async (url) => {
        try {
            // controller = new AbortController();
            url = addKeyToParams(url)
            const response = await axios.get(url, {
                headers: {
                    "Content-Type": "application/json"
                },
            });
            // @ts-ignore
            if (response?.flagStatus === false && response?.error !== "" && response?.error !== null && response?.error !== undefined) {
                alert("Something went wrong.!");
            }
            else {
                return responseBody(response);
            }
        } catch (err) {
            return Error(err);
        }
    },
    post: async (url, body) => {
        try {
            body = addKeyToPayload(body);
            const response = await axios.post(url, body, {
                headers: {
                    "Content-Type": "application/json"
                }
            });
            return responseBody(response);
        } catch (err) {
            return Error(err);
        }
    },
    put: async (url, body) => {
        try {
            body = addKeyToPayload(body);
            const response = await axios.put(url, body, {
                headers: {
                    "Content-Type": "application/json"
                }
            });
            return responseBody(response);
        } catch (err) {
            return Error(err);
        }
    },
    delete: async (url) => {
        try {
            url = addKeyToParams(url); // Add key to URL params
            const response = await axios.delete(url, {
                headers: {
                    "Content-Type": "application/json"
                }
            });
            return responseBody(response);
        } catch (err) {
            return Error(err);
        }
    }
};
const UserMeAPI = {
    get: () => requests.get("/api/user/get-user-detail")
}
const SendLoginOTPAPI = {
    post: (payload) => requests.post("api/user/auth", payload)
}
const VerifyLoginOTPAPI = {
    put: (payload) => requests.put("api/user/auth", payload)
}
const GetCustomerList = {
    get: () => requests.get('api/dev_portal/user/get-user-data')
}
const LogoutUser = {
    post: () => requests.post("api/user/logout-user", {})
}

const getUserDetails = {
    get: (customer_email) => requests.get(`api/dev_portal/user/user-details?customer_email=${customer_email}`)
}

const GetChatbotSystemList = {
    get: () => requests.get('/api/dev_portal/chatbot-system-variable-list')
}
const getChatbotSystemVariableDetail = {
    get: (chatbot_system_variable_id) => requests.get(`/api/dev_portal/chatbot-system-variable-detail?chatbot_system_variable_id=${chatbot_system_variable_id}`)
}
const createChatbotSystemVariable = {
    post: (payload) => requests.post('/api/dev_portal/chatbot-system-variable', payload)
}
const updateChatbotSystemVariable = {
    put: (payload) => requests.put('/api/dev_portal/chatbot-system-variable', payload)
}

const getGenericOutputFormatData = {
    get: () => requests.get("api/global/get-output-format-generic-list")
}
const getGenericModelList = {
    get: () => requests.get("api/dev_portal/prompts/get-model-generic-list")
}
const getLoaderUserTypeDataList = {
    get: () => requests.get('api/popup/get-loader-user-type-list')
}
const getModuleDataList = {
    get: () => requests.get('api/popup/get-module-data-list')
}
const getImageGroups = {
    get: () => requests.get('api/popup/get-image-groups')
}
const uploadFileToGoogleStorage = {
    post: (payload) => requests.post('api/storage/file', payload)
}
const createImageGroupAssignment = {
    post: (payload) => requests.post('api/popup/image-group-assignment', payload)
}
const updateImageGroupAssignment = {
    put: (payload) => requests.put('api/popup/image-group-assignment', payload)
}
const deleteImageGroupAssignment = {
    delete: (image_group_assignment_id, image_group_id) => requests.delete(`api/popup/image-group-assignment?image_group_assignment_id=${image_group_assignment_id}&image_group_id=${image_group_id}`)
}
const contWithGoogle = {
    get: (currentUrl, gc_integration_type_id) => requests.get(`apiIntegration/api/oauth/outbound/auth-url?currentUrl=${currentUrl}&gc_integration_type_id=${gc_integration_type_id}`)
}

const getUserLoginActivity = {
    get: (user_id) => requests.get(`api/dev_portal/user-login-activity?user_id=${user_id}`)
}
const allowDelegateAccess = {
    post: (payload) => requests.post(`api/dev_portal/delegate-access`, payload)
}
const removeDelegateAccess = {
    delete: () => requests.delete(`api/dev_portal/delegate-access`)
}

const getChatbotConversationDetails = {
    get: (user_id,start_date,end_date) => {
        const params = new URLSearchParams();

        params.append('user_id', user_id);
        if (start_date) params.append('start_date', start_date);
        if (end_date) params.append('end_date', end_date);

        return requests.get(`api/dev_portal/get-chatbot-conversations?${params.toString()}`)
    }
}
const getUserChatbotDetails = {
    get: (chatbot_id) => requests.get(`api/dev_portal/get-chatbot-detail?chatbot_id=${chatbot_id}`)
}
const getFileDetail = {
    get: (id) => requests.get("api/storage/file?file_id=" + id + "&flag_info=true")
}
const getLanguageList = {
    get: () => requests.get("api/global/languages")
}

const agent = {
    UserMeAPI,
    SendLoginOTPAPI,
    VerifyLoginOTPAPI,
    GetCustomerList,
    LogoutUser,
    getUserDetails,
    getGenericOutputFormatData,
    getGenericModelList,

    getLoaderUserTypeDataList,
    getModuleDataList,
    getImageGroups,
    uploadFileToGoogleStorage,
    createImageGroupAssignment,
    updateImageGroupAssignment,
    deleteImageGroupAssignment,
    contWithGoogle,
    GetChatbotSystemList,
    getChatbotSystemVariableDetail,
    createChatbotSystemVariable,
    updateChatbotSystemVariable,
    getUserLoginActivity,
    allowDelegateAccess,
    removeDelegateAccess,
    getChatbotConversationDetails,
    getUserChatbotDetails,
    getFileDetail,
    getLanguageList
};

export default agent;