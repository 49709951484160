import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Grid } from "@mui/material";
import styles from "../assets/styles/header.module.css";
import { UserContext } from "../context/userContext";


export default function Header(props) {
    const {headerTitle} = React.useContext(UserContext)
    return (
        <Box className={styles.headerBox}>
            <Box
                className={styles.header}>
                {/* <Toolbar> */}
                    <Grid
                        container
                        justifyContent={"start"}
                        alignItems={"center"}
                    >
                        <Grid item>
                            <Grid container justifyContent={"start"}>
                                <Grid item>
                                    {headerTitle !== "" &&
                                    <Typography
                                        variant="h6"
                                        component="div"
                                        className={styles.headerTextStyle}
                                    >
                                        {headerTitle}
                                    </Typography>}
                                </Grid>

                            </Grid>
                        </Grid>
                    </Grid>
            </Box>
        </Box>
    );
}
