
import React, { useEffect, useState } from 'react';
import { Box, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from '@mui/material';
import RoundLoader from "../components/roundLoader";
import globalStyles from "../assets/styles/global.module.scss";
import GlobalDataGrid from '../components/globalDataGrid';
import agent from "../api/api";
import GlobalSearch from '../utils/globalSearch';
import { UserContext } from '../context/userContext';
import { checkForErrorCode, getErrorMessage } from '../utils/constants';
import RefreshIcon from '@mui/icons-material/Refresh';
import styles from '../assets/styles/existingCustomers.module.scss'
import { makeStyles } from '@mui/styles';
import { FixedSizeList as List } from 'react-window';
import { useNavigate, useSearchParams } from 'react-router-dom';
import LoginActivity from './loginActivity';
import { ChatbotListContext } from '../context/chatbotListContext';

const ExistingCustomers = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [customerDetail, setCustomerDetail] = useState(null);
  const navigate = useNavigate()
  const [showLoader, setShowLoader] = React.useState(false);
  const [customerList, setCustomerList] = React.useState([]);
  const [rowFilteredDetails, setRowFilteredDetails] = React.useState(null);
  const [filterValues, setFilterValues] = React.useState(null);
  const { setError, error, setFlagLoading, flagLoading } = React.useContext(UserContext);
  const [sortedData, setSortedData] = useState([]);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: '' });
  const [changeField, setChangeField] = useState("");
  const [loginActivity, setLoginActivity] = useState([]);
  const { getChatbotListConversation, chatbotConversation, setChatbotConversation, setChatbotList } = React.useContext(ChatbotListContext);
  const [selectedRow, setSelectedRow] = React.useState({
    value: "",
    fieldName: ""
  });
  const user_id= searchParams.get('user_id')

  const columns = [
    { field: 'userId', headerName: 'User ID', width: 90 },
    { field: 'userFirstName', headerName: 'First Name', width: 150 },
    { field: 'userLastName', headerName: 'Last Name', width: 150 },
    { field: 'userEmail', headerName: 'Email', width: 230 },
    { field: 'totalChatbots', headerName: 'Total Chatbots', width: 150 },
    { field: 'totalConversations', headerName: 'Total Conversations', width: 230 },
    { field: 'last7daysConversation', headerName: 'Last 7 days Conversation', width: 230 },
    { field: 'mouseflow_record_url', headerName: 'Mouseflow Filter', width: 250 },
    { field: 'userPhoneNumber', headerName: 'Phone Number', width: 150 },
    { field: 'userLastLogin', headerName: 'Last Login', width: 170 },
    { field: 'userRegistered', headerName: 'Registered', width: 170 },
    { field: 'registerTimestamp', headerName: 'Register Timestamp', width: 190 },
    { field: 'userStatusId', headerName: 'Status ID', width: 100 },
    { field: 'useStatusName', headerName: 'Status Name', width: 130 },
    { field: 'userRoleId', headerName: 'Role ID', width: 100 },
    { field: 'userRoleName', headerName: 'Role Name', width: 125 },
    { field: 'userPersonaId', headerName: 'Persona ID', width: 120 },
    { field: 'highPotentialUser', headerName: 'High Potential User', width: 170 },
    { field: 'user_assignment_id', headerName: 'User_assignment ID', width: 190 },
  ];

  const rows = customerList !== null && customerList.map((item) => ({
    id: item.private_user_id,
    user_assignment_id: item.user_assignment_id,
    userFirstName: item.private_user_first_name,
    userLastName: item.private_user_last_name ? item.private_user_last_name : "-",
    userEmail: item.private_user_email,
    mouseflow_record_url: item.mouseflow_record_url,
    userPhoneNumber: item.private_user_phone_number ? item.private_user_phone_number : "-",
    userLastLogin: item.user_last_login,
    userRegistered: item.user_registered,
    registerTimestamp: new Date(item.register_timestamp).toLocaleString(),
    userStatusId: item.user_status_id,
    useStatusName: item.user_status_name,
    userRoleId: item.private_user_role_id,
    userRoleName: item.user_role_name,
    userPersonaId: item.persona_id ? item.persona_id : "-",
    highPotentialUser: item.high_potential_user ? item.high_potential_user : "-",
  }));


  const getCustomerListData = async () => {
    setFilterValues("")
    setSortConfig({...sortConfig, direction: ""})
    // setShowLoader(true)
    const response = await agent.GetCustomerList.get();
    // setShowLoader(false)
    if (response.status) {
      if (response.data) {

        let customerList = response.data.map((customer, index) => ({
          id: index,
          userId: customer.private_user_id,
          userFirstName: customer.private_user_first_name,
          userLastName: customer.private_user_last_name ? customer.private_user_last_name : "-",
          userEmail: customer.private_user_email,
          totalChatbots: customer.total_chatbot,
          totalConversations: customer.total_conversations,
          last7daysConversation: customer.last_seven_days_conversation,
          mouseflow_record_url: customer.mouseflow_record_url,
          userPhoneNumber: customer.private_user_phone_number ? customer.private_user_phone_number : "-",
          userLastLogin: customer.user_last_login,
          userRegistered: customer.user_registered,
          registerTimestamp: new Date(customer.register_timestamp).toLocaleString(),
          userStatusId: customer.user_status_id,
          useStatusName: customer.user_status_name,
          userRoleId: customer.private_user_role_id,
          userRoleName: customer.user_role_name,
          userPersonaId: customer.persona_id ? customer.persona_id : "-",
          highPotentialUser: customer.high_potential_user ? customer.high_potential_user : "-",
          user_assignment_id: customer.user_assignment_id,
        }))
        setRowFilteredDetails(customerList)
        setCustomerList(customerList)
        setCustomerDetail(customerList.find((list)=>list.userId === Number(user_id)))
      }
    }
    else {
      let errorCode = checkForErrorCode(response)
      setError({ ...error, flagShowError: true, message: getErrorMessage(errorCode) })
    }
  }

  React.useEffect(() => {
    getCustomerListData();
  }, [])

  const getLoginActivity = async (userID) => {
    // debugger
    setFlagLoading((prevState) => (true))
    if(user_id) {
        const response = await agent.getUserLoginActivity.get(userID)
        getChatbotListConversation(user_id)
        if (response.status && response.data) {
            setLoginActivity(response.data);
        }
        else {
            setError({ ...error, flagShowError: true, message: response.error?.message || "Something Went Wrong!" });
        }
    }    
    setFlagLoading((prevState) => (false))

  }
  function resetState(){
    setLoginActivity([])
    setChatbotConversation(null)
    setChatbotList([])
  }
  React.useEffect(() => {
    resetState()
    if(user_id) {
      getLoginActivity(user_id);
    }
  }, [user_id])

  const handleSearch = (event) => {
    setSortConfig({...sortConfig, direction: ""})
    let query = event.target.value.toLowerCase().trim()
    setFilterValues(query)
    if (query && query.length > 0) {
      const filteredActiveChatsTemp = customerList.filter((customer) => {
        return (
          JSON.stringify(customer)?.toLowerCase().includes(query)
        )
      })
      setRowFilteredDetails(filteredActiveChatsTemp)
      setSortedData(filteredActiveChatsTemp)
      setCustomerDetail(filteredActiveChatsTemp.find((list)=>list.userId === user_id))
    } else{
      setRowFilteredDetails(customerList)
      setSortedData(customerList)
      setCustomerDetail(customerList.find((list)=>list.userId === user_id))
    }
  }

  const handleClearSearch = () => {
    setFilterValues("");
    setRowFilteredDetails(customerList);
    setSortConfig({...sortConfig, direction: ""})
  }

  const handleRowClick = (params, field) => {
      setSelectedRow({...selectedRow, value: params, fieldName: field});
      if(field === "mouseflow_record_url") {
        window.open(params, '_blank')
      }
  };


  const useStyles = makeStyles(() => ({
    customTooltip: {
        backgroundColor: '#FFF',
        color: '#000',
        fontSize: '13px',
        padding: '8px 15px',
        borderRadius: 5,
        margin: "0px",
        boxShadow: "0px 0px 3px rgba(0,0,0,0.25)"
    },
  }));
  const classes = useStyles();

  useEffect(() => {
    if (rowFilteredDetails && rowFilteredDetails.length > 0) {
      setSortedData([...rowFilteredDetails]);
    }
  }, [rowFilteredDetails]);

  const handleSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setChangeField(key);
    const sorted = [...sortedData].sort((a, b) => {
    const aValue = a[key] || "";
    const bValue = b[key] || "";

    if (aValue === "" && bValue === "") {
      return 0;
    }

    if (aValue === "") {
      return direction === 'ascending' ? -1 : 1;
    }

    if (bValue === "") {
      return direction === 'ascending' ? 1 : -1;
    }
    
    if (aValue < bValue) {
      return direction === 'ascending' ? -1 : 1;
    }

    if (aValue > bValue) {
      return direction === 'ascending' ? 1 : -1;
    }

    return 0;
    });

    setSortedData(sorted);
    setSortConfig({ key, direction });
  };

  const renderTableRow = ({ index, style }) => {
    const row = sortedData[index];
    return (
      <TableRow className={styles.tableBodyRow} style={style} key={row.id}>
        <TableCell sx={{ minWidth: "80px", maxWidth: "80px", cursor: "pointer" }} className={`${styles.tableBodyCell} ${styles.hoverTableCell}`} onClick={() => { handleRowClick(row.userId, "userId"); navigate(`${window.location.pathname}?user_id=${row.userId}`); setCustomerDetail(row)}}>
          <Tooltip title={row.userId} classes={{ tooltip: classes.customTooltip }} placement='right-start'>{row.userId}</Tooltip>
        </TableCell>
        <TableCell sx={{ minWidth: "138px", maxWidth: "138px" }} className={styles.tableBodyCell} onClick={() => { handleRowClick(row.userFirstName, "userFirstName") }}>
          <Tooltip title={row.userFirstName} classes={{ tooltip: classes.customTooltip }} placement='right-start'>{row.userFirstName?.length > 12 ? row.userFirstName.slice(0, 12) + "..." : row.userFirstName}</Tooltip>
        </TableCell>
        <TableCell sx={{ minWidth: "138px", maxWidth: "138px" }} className={styles.tableBodyCell} onClick={() => { handleRowClick(row.userLastName, "userLastName") }}>
          <Tooltip title={row.userLastName} classes={{ tooltip: classes.customTooltip }} placement='right-start'>{row.userLastName}</Tooltip>
        </TableCell>
        <TableCell sx={{ minWidth: "220px", maxWidth: "220px", cursor: "pointer" }} className={`${styles.tableBodyCell} ${styles.hoverTableCell}`} onClick={() => { handleRowClick(row.userEmail, "userEmail"); navigate(`${window.location.pathname}?user_id=${row.userId}`); setCustomerDetail(row) }}>
          <Tooltip title={row.userEmail} classes={{ tooltip: classes.customTooltip }} placement='right-start'>{row.userEmail}</Tooltip>
        </TableCell>
        <TableCell sx={{ minWidth: "140px", maxWidth: "140px" }} className={styles.tableBodyCell} onClick={() => { handleRowClick(row.totalChatbots, "totalChatbots") }}>
          <Tooltip title={row.userLastName} classes={{ tooltip: classes.customTooltip }} placement='right-start'>{row.totalChatbots}</Tooltip>
        </TableCell>
        <TableCell sx={{ minWidth: "220px", maxWidth: "220px" }} className={styles.tableBodyCell} onClick={() => { handleRowClick(row.totalConversations, "totalConversations") }}>
          <Tooltip title={row.userLastName} classes={{ tooltip: classes.customTooltip }} placement='right-start'>{row.totalConversations}</Tooltip>
        </TableCell>
        <TableCell sx={{ minWidth: "220px", maxWidth: "220px" }} className={styles.tableBodyCell} onClick={() => { handleRowClick(row.last7daysConversation, "last7daysConversation") }}>
          <Tooltip title={row.userLastName} classes={{ tooltip: classes.customTooltip }} placement='right-start'>{row.last7daysConversation}</Tooltip>
        </TableCell>
        <TableCell sx={{ minWidth: "250px", maxWidth: "250px", cursor: "pointer" }} className={styles.tableBodyCell} onClick={() => { handleRowClick(row.mouseflow_record_url, "mouseflow_record_url") }}>
          <Tooltip title={row.mouseflow_record_url} classes={{ tooltip: classes.customTooltip }} placement='right-start'>{row.mouseflow_record_url.length > 25 ? row.mouseflow_record_url.slice(0, 25) + "..." : row.mouseflow_record_url}</Tooltip>
        </TableCell>
        <TableCell sx={{ minWidth: "120px", maxWidth: "120px" }} className={styles.tableBodyCell} onClick={() => { handleRowClick(row.userPhoneNumber, "userPhoneNumber") }}>
          <Tooltip title={row.userPhoneNumber} classes={{ tooltip: classes.customTooltip }} placement='right-start'>{row.userPhoneNumber}</Tooltip>
        </TableCell>
        <TableCell sx={{ minWidth: "160px", maxWidth: "160px" }} className={styles.tableBodyCell} onClick={() => { handleRowClick(row.userLastLogin, "userLastLogin") }}>
          <Tooltip title={row.userLastLogin} classes={{ tooltip: classes.customTooltip }} placement='right-start'>{row.userLastLogin}</Tooltip>
        </TableCell>
        <TableCell sx={{ minWidth: "170px", maxWidth: "170px" }} className={styles.tableBodyCell} onClick={() => { handleRowClick(row.userRegistered, "userRegistered") }}>
          <Tooltip title={row.userRegistered} classes={{ tooltip: classes.customTooltip }} placement='right-start'>{row.userRegistered}</Tooltip>
        </TableCell>
        <TableCell sx={{ minWidth: "190px", maxWidth: "190px" }} className={styles.tableBodyCell} onClick={() => { handleRowClick(row.registerTimestamp, "registerTimestamp") }}>
          <Tooltip title={row.registerTimestamp} classes={{ tooltip: classes.customTooltip }} placement='right-start'>{row.registerTimestamp}</Tooltip>
        </TableCell>
        <TableCell sx={{ minWidth: "80px", maxWidth: "80px" }} className={styles.tableBodyCell} onClick={() => { handleRowClick(row.userStatusId, "userStatusId") }}>
          <Tooltip title={row.userStatusId} classes={{ tooltip: classes.customTooltip }} placement='right-start'>{row.userStatusId}</Tooltip>
        </TableCell>
        <TableCell sx={{ minWidth: "130px", maxWidth: "130px" }} className={styles.tableBodyCell} onClick={() => { handleRowClick(row.useStatusName, "useStatusName") }}>
          <Tooltip title={row.useStatusName} classes={{ tooltip: classes.customTooltip }} placement='right-start'>{row.useStatusName}</Tooltip>
        </TableCell>
        <TableCell sx={{ minWidth: "80px", maxWidth: "80px" }} className={styles.tableBodyCell} onClick={() => { handleRowClick(row.userRoleId, "userRoleId") }}>
          <Tooltip title={row.userRoleId} classes={{ tooltip: classes.customTooltip }} placement='right-start'>{row.userRoleId}</Tooltip>
        </TableCell>
        <TableCell sx={{ minWidth: "125px", maxWidth: "125px" }} className={styles.tableBodyCell} onClick={() => { handleRowClick(row.userRoleName, "userRoleName") }}>
          <Tooltip title={row.userRoleName} classes={{ tooltip: classes.customTooltip }} placement='right-start'>{row.userRoleName}</Tooltip>
        </TableCell>
        <TableCell sx={{ minWidth: "110px", maxWidth: "110px" }} className={styles.tableBodyCell} onClick={() => { handleRowClick(row.userPersonaId, "userPersonaId") }}>
          <Tooltip title={row.userPersonaId} classes={{ tooltip: classes.customTooltip }} placement='right-start'>{row.userPersonaId}</Tooltip>
        </TableCell>
        <TableCell sx={{ minWidth: "160px", maxWidth: "160px" }} className={styles.tableBodyCell} onClick={() => { handleRowClick(row.highPotentialUser, "highPotentialUser") }}>
          <Tooltip title={row.highPotentialUser} classes={{ tooltip: classes.customTooltip }} placement='right-start'>{row.highPotentialUser}</Tooltip>
        </TableCell>
        <TableCell sx={{ minWidth: "160px", maxWidth: "160px" }} className={styles.tableBodyCell} onClick={() => { handleRowClick(row.user_assignment_id, "user_assignment_id"); }}>
          <Tooltip title={row.user_assignment_id} classes={{ tooltip: classes.customTooltip }} placement='right-start'>{row.user_assignment_id}</Tooltip>
        </TableCell>
      </TableRow>
    );
  };

  const renderTableColumn = () => {
    return (
      <TableRow className={styles.tableHeadRow}>
          <Grid container direction={"row"} flexWrap={"nowrap"} gap={2.5}>
            {columns.map((column) => (
              <Grid item key={column.field} sx={{minWidth: column.width, cursor: "pointer", padding: "5px 5px 5px 10px"}} onClick={() => handleSort(column.field)}>
                <Grid container direction={"row"} justifyContent={"space-between"} flexWrap={"nowrap"} alignItems={"center"}>
                  <Grid item>
                    {column.headerName}
                  </Grid>
                  <Grid item direction={"column"}>
                    <Grid container direction={"column"}>
                      <Typography variant='span' style={{color: sortConfig.direction==="ascending" && column.field === changeField ? "rgba(0,0,0,0.85)" : "rgba(0,0,0,0.1)", lineHeight: "normal", fontSize: "12px"}}>▲</Typography>
                      <Typography variant='span' style={{color: sortConfig.direction==="descending" && column.field === changeField ? "rgba(0,0,0,0.85)" : "rgba(0,0,0,0.1)", lineHeight: "normal", fontSize: "12px"}}>▼</Typography>
                    </Grid>                                
                  </Grid>
                </Grid>
              </Grid>
            ))}
            </Grid>
      </TableRow>
    );
  };

  return (
    !user_id ?
    <>
      {
        showLoader && <RoundLoader />
      }

      <Grid container className={globalStyles.containerflexWrapStyle} direction={"row"} xs={12} gap={1} alignItems={"center"} justifyContent={"end"} mb={1}>
        <Grid item marginRight={"15px"}>
          <span style={{fontSize: "14px", fontWeight: "500"}}>Existing Customers: </span><span style={{fontSize: "15px", fontWeight: "600"}}>{customerList.length}</span>
        </Grid>
        <Grid item className={globalStyles.searchBarFieldGrid}>
          <GlobalSearch placeholder="Search Customer" value={filterValues} onChange={(e) => { handleSearch(e) }} onClose={handleClearSearch} />
        </Grid>
        <Box display={"flex"} justifyContent={"end"}>
          <RefreshIcon onClick={getCustomerListData} className={globalStyles.clickable} />
        </Box>
      </Grid>

      <Grid container className={globalStyles.containerflexWrapStyle} direction={"column"}>
        <Grid item sx={{boxShadow: "0px 0px 3px rgba(0,0,0,0.15)"}}>
            {!flagLoading &&
            <TableContainer className={styles.tableContainer}>
                <Table aria-label='simple table' className={styles.tableDesign}>
                  <TableHead className={styles.tableHead}>
                    {renderTableColumn()}
                  </TableHead>

                  <TableBody className={styles.tableBody}>
                    {(sortedData && sortedData.length > 0) ?
                      <List
                        height={750}  // Adjust this value based on the desired height of the table
                        itemCount={sortedData.length}
                        itemSize={50}  // Adjust based on row height
                        width="100%"
                        className={styles.listData}
                      >
                        {renderTableRow}
                      </List>
                      :
                      <TableRow className={styles.tableBodyRow}>
                        <TableCell colSpan={8} sx={{ textAlign: "left", padding: "16px 35vw 32px" }}>
                          <span style={{ position: "fixed" }}>No Data Available</span>
                        </TableCell>
                      </TableRow>
                    }
                  </TableBody>

                </Table>
            </TableContainer>}
        </Grid>
      </Grid>

      {selectedRow.fieldName != "" && (
          <div className={styles.showDetailsBlock}>
              <div className={styles.showDetailsTitleField}><Typography>{selectedRow.fieldName}</Typography></div>
              <div className={styles.showDetailsDataField}><Typography>{selectedRow.value}</Typography></div>
          </div>
      )}
    </>
    :
    <>
      {!flagLoading && <LoginActivity sortedData={customerDetail} loginActivity={loginActivity} setLoginActivity={setLoginActivity} chatbotConversation={chatbotConversation}/>}
    </>
  )
}

export default ExistingCustomers