import logo from './logo.svg';
import './App.css';
import { Fragment } from 'react';
import GlobalRoutes from './routes/routes';
import { UserProvider } from './context/userContext';
import { UserDetailsProvider } from './context/userDetailsContext';
import { GenericDataProvider } from './context/GenericData';
import { ChatbotSystemDetailsProvider } from './context/chatbotSystemContext'
import { ChatbotListsDetailsProvider } from './context/chatbotListContext';

function App() {
  return (
    <Fragment>
      <GenericDataProvider>
        <UserProvider>
            <UserDetailsProvider>
              <ChatbotSystemDetailsProvider>
                <ChatbotListsDetailsProvider>
                  <GlobalRoutes />
                </ChatbotListsDetailsProvider>
              </ChatbotSystemDetailsProvider>
            </UserDetailsProvider>
        </UserProvider>
      </GenericDataProvider>
    </Fragment>
  );
}

export default App;
