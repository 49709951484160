import { Grid } from '@mui/material'
import React from 'react'
import DownloadIcon from '@mui/icons-material/Download';
import styles from '../../assets/styles/chatbotLists.module.scss'

const FileUpload = (props) => {

    const handleFileDownload = (file) => {
        if (file.original_file_name || file.file_name) {
            if (file.google_storage_id) {
                fetch(`${process.env.REACT_APP_BASE_URL}/api/storage/file?file_id=${file.google_storage_id}&flag_info=false`)
                    .then(response => {
                        if (!response.ok) {
                            throw new Error('Network response was not ok');
                        }
                        return response.blob();
                    })
                    .then(blob => {
                        const fileToDownload = new File([blob], `${file.original_file_name || file?.file_name}`);
                        const url = URL.createObjectURL(fileToDownload);
                        const a = document.createElement('a');
                        a.href = url;
                        a.download = fileToDownload.name;
                        document.body.appendChild(a);
                        a.click();
                        console.log('reached here')
                        // Clean up
                        document.body.removeChild(a);
                        URL.revokeObjectURL(url);
                    })
                    .catch(error => {
                        console.error('There was a problem with the fetch operation:', error);
                    });
            }
        }
    }

    const formatFileSize = (bytes, decimalPoint) => {
        if (bytes == 0) return '0 Bytes';
        var k = 1000,
            dm = decimalPoint || 2,
            sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
            i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }

    return (
        <>
            {props.filesFromParent && props.filesFromParent.map((fileArray) => {

                var roundedNumber = formatFileSize(fileArray.size ? fileArray.size : 0)
                return <>
                    {fileArray.page_field_id == props.page_field_id && <Grid Container sx={{ width: "100%" }} className={fileArray.previewImageSrc ? styles.fileUploadedGrid : styles.file_background_design} mt={1}>
                        <Grid item direction="column">
                            <Grid container alignItems="center" className={styles.delete_container}>
                                <a rel="noreferrer" href={`${process.env.REACT_APP_BASE_URL}/api/storage/file?file_id=${fileArray.google_storage_id}&flag_info=false`} download="" target="_blank" style={{ color: "unset", textDecoration: "unset", width: "90%" }}>
                                    <Grid sx={{ width: "100%" }} item xs={11} className={styles.file_name} >
                                        <div style={{ display: "flex", alignItems: "center", flexWrap: "wrap", whiteSpace: "nowrap", wordBreak: "break-all", width: "100%" }}>
                                            <div style={{ display: "flex", alignItems: "center", flexWrap: "nowrap", whiteSpace: "nowrap", wordBreak: "break-all" }}>
                                                {fileArray.previewImageSrc &&
                                                    <img className={styles.file_type_uploaded} src={fileArray.previewImageSrc} alt="file" style={{ width: "32px", height: "32px", marginRight: "8px" }} />
                                                }
                                                <span style={{ whiteSpace: "pre-wrap", fontSize: "13px", fontWeight: "500" }} className="file_name_txt">{fileArray.name}</span>
                                            </div>
                                            &nbsp;
                                            &nbsp;
                                            <div>
                                                <span className="file_size"> {roundedNumber}</span>
                                            </div>

                                        </div>
                                    </Grid>
                                </a>
                                {(fileArray.google_storage_id) &&
                                    <>
                                        <Grid item paddingRight={"10px"}>
                                            <Grid container gap="10px">
                                                <Grid item sx={{ cursor: "pointer" }} onClick={() => { handleFileDownload(fileArray) }}>
                                                    <DownloadIcon width="25px" height="25px" sx={{display: "flex"}}/>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </>
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                    }
                    
                </>
            })}
            {props.filesFromParent && props.filesFromParent.filter((file) => file.page_field_id == props.page_field_id).length == 0 && <Grid Container sx={{ width: "100%" }} className={styles.fileUploadedGrid} mt={1} justifyContent={"center"}>
                    <Grid item sx={{padding: "8px", width: "100%", textAlign: "center"}}>
                        <span style={{fontSize: "14px", fontWeight: "600"}}>No any Files Uploaded Here.</span>
                    </Grid>
                </Grid>
            }
        </>
    )
}

export default FileUpload
